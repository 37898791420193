import { apiAddress } from "../constants";
import axios from "axios";

export default async function SaveSelfServiceInfo(account, token) {
  try {
    const json = JSON.stringify({
      firstName: account.firstName,
      lastName: account.lastName,
      country: account.country,
      state: account.state === "N/A" ? "" : account.state,
      language: account.language,
      jobRole: account.jobRole,
      title: account.title,
      phoneNumber: account.phone,
      phoneExt: account.extension,
      verificationToken: token,
      salesforceId: account.salesforceId,
      contactRegionExternalDetailsId: account.contactRegionExternalDetailsId,
    });

    const request = await axios.post(`${apiAddress}/SaveSelfServiceInfo`, json);
    return { status: request.status, data: request.data };
  } catch (e) {
    console.log("error in selfRegistration :" + e);
    console.log(e.status);
    return { status: e.status, data: "" };
  }
}
