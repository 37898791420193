import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import usePrevious from "./usePrevious";

const useHasChanged = (value) => {
  const previousValue = usePrevious(value);
  if (!previousValue) return false;
  return previousValue !== value;
};

const StateSelect = (props) => {
  const { values, touched, setFieldValue, errors } = useFormikContext();

  const [field] = useField("state");
  const [states, setStates] = useState([]);
  const isCountryChanged = useHasChanged(values.country);

  useEffect(() => {
    if (props.statesByCountry.length === 0) {
      return;
    }
    const statesByCountry = props.statesByCountry.find(
      (obj) => obj.country === values.country
    );
    if (!statesByCountry) {
      setStates([]);
      setFieldValue("state", "N/A");
      return;
    }
    if (!isCountryChanged) {
      setStates(statesByCountry.states);
      return;
    }
    setFieldValue("state", "");
  }, [values.country, setFieldValue, props.statesByCountry, isCountryChanged]);

  return (
    <>
      <label style={{ display: "block" }}>
        {states.length !== 0
          ? props.labels?.trial_state_wizardPage + "*"
          : props.labels?.trial_state_wizardPage}
      </label>

      <FormControl fullWidth className="InputElem">
        <Select
          labelId="state"
          id="state-select"
          variant="outlined"
          name="state"
          {...field}
          required={states.length !== 0 ? true : false}
          value={states.length !== 0 ? values.state : "N/A"}
          disabled={states.length !== 0 ? false : true}
          error={Boolean(touched.state && errors.state)}
        >
          {states.length !== 0 ? (
            states.map((state) => {
              return (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem key="noStateOptions" value="N/A">
              N/A
            </MenuItem>
          )}
        </Select>
        <FormHelperText error>{touched.state && errors.state}</FormHelperText>
      </FormControl>
    </>
  );
};

export default StateSelect;
