import { apiAddress } from "../constants";
import axios from "axios";

export default async function validateContact(workEmail) {
  try {
    const json = JSON.stringify({ email: workEmail });

    const request = await axios.post(
      `${apiAddress}/GetContactValidation`,
      json
    );
    return {status: request.status, data: request.data};
  } catch (e) {
    return { status: e.response.status, data: e.response.data.message };
  }
}
