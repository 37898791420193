import React, { useState, useEffect, useContext } from "react";
import { MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import AppContext from "../Context/appContext";

import fetchTranslations from "../API/methods/fetchTranslations";
import { Link, useLocation } from "react-router-dom";
import logo from "../Assets/Bluebeam-Logo.png";
import { flagUrls } from "../API/constants";

const Navbar = () => {
  const appContext = useContext(AppContext);
  const { changeLanguage, appState, updateIsTranslationsFetched } = appContext;
  const [languages, setLanguages] = useState([]);
  const { pathname } = useLocation();
  const [reg, setRegion] = useState("");
  const [flag, setFlag] = useState("");
  const [lang, setLanguage] = useState(
    appState.language.label !== undefined
      ? appState.language
      : JSON.parse(appState.language)
  );

  const regionsSelectionString = process.env.REACT_APP_REGION_SELECTOR || '[{"id": "us-east-1", "label": "USA-l", "url":""},{"id":"eu-central-1", "label":"GER-l", "url":""},{"id":"eu-north-1", "label":"SWE-l", "url":""},{"id":"ap-southeast-2", "label":"AU-l", "url":""}]'
  let regionsSelection = JSON.parse(regionsSelectionString)

  const awsRegion = process.env.REACT_APP_AWS_REGION || "us-east-1"

  useEffect(() => {
    async function fetchLanguages() {
      let sessionLanguages = sessionStorage.getItem("isLanguagesFetched");

      let response = await fetchTranslations();
      if (response && response.languages) {
        setLanguages(response.languages); 
        sessionStorage.setItem("isTranslationsFetched", "true");
        sessionStorage.setItem("languages", JSON.stringify(response.languages));
        sessionStorage.setItem("labels", JSON.stringify(response));
        updateIsTranslationsFetched({
          ...appState,
          isTranslationsFetched: true,
        });
        verifyRegion()
        handleFlag()
      }
    }

    fetchLanguages();
  }, []);

  useEffect(() => {
    setFlag(flagUrls[lang.key])
  },[lang]);

  const verifyRegion = () => {
    for(let i = 0; i < regionsSelection.length; i++){
      if(regionsSelection[i].id === awsRegion){
        setRegion(regionsSelection[i].id)     
        return
      }
    }
  }

  const handleFlag = () =>{
    setFlag(flagUrls[lang.key])
  }


  // default to english
  const handleChange = (e) => {
    setLanguage(languages[e.target.value]);
    localStorage.setItem("language", JSON.stringify(languages[e.target.value]));
    changeLanguage({
      ...appState,
      language: languages[e.target.value],
    });
  };


  return (
    <>
      {pathname !== "/authorize_callback" ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <Link to="/">
            <img src={logo} alt="bluebeam logo" style={{ height: 45 }}></img>
          </Link>
          <div>
            {languages.length !== 0 ? (
              <div
                style={{
                  display: "flex",
                }}
              >              
                 <img src={flag.url} alt="bluebeam logo" style={{ height: 25,marginTop: "4px",
                    marginRight: "5px", }}></img>
                <TextField
                  select
                  value={lang.key}
                  onChange={handleChange}
                  variant="standard"
                  style={{
                    width: 200,
                    border: "none !important",
                    outline: "none",
                  }}
                  inputProps={{
                    outline: {
                      borderBottom: "none",
                    },
                    border: {
                      border: "none",
                    },
                  }}
                >
                  {languages.map((ack) => (
                    <MenuItem key={ack.key} value={ack.key}>
                      {ack.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Navbar;
