import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import AppContext from "../../Context/appContext";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import selfRegistration from "../../API/methods/selfRegistration";
import saveSelfServiceInfo from "../../API/methods/saveSelfServiceInfo";
import verifyAccount from "../../API/methods/verifyAccount";
import { login } from "../../API/methods/login";
import useTranslations from "../../Components/useTranslations";
import usePicklistValues from "../../Components/usePicklistValues";
import StateSelect from "../../Components/stateSelect";
import { UUIDSchema } from "../../Components/schemas";

const ACCOUNT_VALIDATED = "AccountValidated";

const RegisterPage = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { updateLanguageCountry, appState } = appContext;

  const [account, setAccount] = useState({
    firstName: "",
    lastName: "",
    displayName: "",
    email: "",
    salesforceId: "",
    mailingCountry: "",
    mailingState: "",
    phone: "",
    phoneExt: "",
    salutation: "",
    title: "",
    preferredLanguage: "",
    needPasswordReset: false,
  });
  const [isPageLoading, setPageLoading] = useState(false); //change to true

  const { id } = useParams();

  useEffect(() => {
    // for dev:
    /*setAccount({
      firstName: "response.data.firstName",
      lastName: "response.data.lastName",
      displayName: "",
      email: "response.data.email",
    });*/
    setPageLoading(false);
    if (UUIDSchema.isValidSync(id)) {
      getAccountDetails(id);
    } else {
      navigate("/InvalidInvite");
    }
  }, [id]);

  const tLabels = useTranslations(appState);
  const valuesLoaded = usePicklistValues(appState, updateLanguageCountry);
  if (!valuesLoaded) navigate("/");

  const { countries, states, languages, jobRoles } = appState;

  const getAccountDetails = async (id) => {
    const response = await verifyAccount(id);
    if (!response || response.status !== 200) {
      navigate("/InvalidInvite");
      return;
    }
    setAccount({
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      displayName: "",
      email: response.data.email,
      salesforceId: response.data.contactId,
      mailingCountry: response.data.mailingCountry,
      mailingState: response.data.mailingState,
      phone: response.data.phone,
      phoneExt: response.data.phoneExt,
      jobRole: response.data.jobRole,
      title: response.data.title,
      preferredLanguage: response.data.preferredLanguage,
      needPasswordReset: response.data.needPasswordReset,
    });
    if (response.data.isSSOUser) {
      await login(appState, "RegisterPage", id);
      return;
    }
    if (
      response.data.preCreatedAccountStatus &&
      response.data.preCreatedAccountStatus === ACCOUNT_VALIDATED &&
      !response.data.needPasswordReset
    ) {
      await login(appState, "RegisterPage", id);
      return;
    }
    setPageLoading(false);
  };

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false); // set true if response !== success
  const [errorMsg, setErrorMsg] = useState(
    tLabels?.trial_server_error_wizardPage
  );

  const initialValues = {
    firstName: account.firstName || "",
    lastName: account.lastName || "",
    blueBeamDisplayName: "",
    password: "",
    confirmPassword: "",
    country: account.mailingCountry || "",
    state: account.mailingState || "",
    userLanguage: account.preferredLanguage || "",
    jobRole: account.jobRole || "",
    title: account.title || "",
    phoneNumber: account.phone || "",
    extension: account.phoneExt || "",
    salesforceId: account.salesforceId,
  };

  const next = async (acc) => {
    setLoading(true);

    const registarationResponse = await selfRegistration(acc, id);

    if (registarationResponse && registarationResponse.status === 200) {
      const saveInfoResponse = await saveSelfServiceInfo(acc, id);
      if (
        saveInfoResponse &&
        (saveInfoResponse.status === 200 ||
          saveInfoResponse.status === 201 ||
          // bad gateway because sometimes Salesforce might time out faster than API Gateway (29 seconds) (Otto)
          saveInfoResponse.status === 502)
      ) {
      }
      setLoading(false);
      navigate("/RegistrationCompleted");
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <React.Fragment>
      {isPageLoading ? (
        <div
          className="loader-container"
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid
          container
          item
          md={8}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            border: "1px solid #DADADA",
            marginBottom: 50,
            marginTop: 50,
          }}
        >
          <Grid container style={{ padding: 20 }} alignItems="center">
            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="body1">
                {tLabels?.trial_revu_download_hasAcc_indexPage}
                <span
                  className="cSpan"
                  style={{
                    color: "#0099DE",
                    height: 20,
                  }}
                  onClick={() => login(appState, "RegisterPage", id)}
                >
                  {tLabels?.trial_revu_download_clickLogin_registerPage}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              variant="h6"
              style={{
                color: "",
                fontSize: 20,
                fontWeight: 900,
                marginLeft: 20,
              }}
            >
              {tLabels?.trial_heading_verifyAccountPage}
            </Typography>
            <Box
              style={{
                background: "#F1F2F2",
              }}
            >
              <Grid
                item
                xs={10}
                md={12}
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    marginTop: 42,
                    color: "",
                    fontSize: 20,
                    fontWeight: 900,
                    paddingTop: 42,
                    marginLeft: 10,
                  }}
                >
                  {tLabels?.trial_heading_contact_details}
                </Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ marginTop: 38, marginBottom: 30 }}
                  >
                    <label style={{ display: "block", fontWeight: "bold" }}>
                      {tLabels?.trial_email_verifyAccountPage}
                    </label>
                    <label style={{ display: "block", paddingTop: 20 }}>
                      {account.email}
                    </label>
                  </Grid>
                </Grid>
                <Formik
                  enableReinitialize
                  validateOnMount
                  initialTouched={{
                    phoneNumber: true,
                    extension: true,
                  }}
                  initialValues={{
                    ...initialValues,
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().required(
                      tLabels?.trial_firstName_error_verifyAccountPage
                    ),
                    lastName: Yup.string().required(
                      tLabels?.trial_lastName_error_verifyAccountPage
                    ),
                    blueBeamDisplayName: Yup.string().required(
                      tLabels?.trial_displayName_error_verifyAccountPage
                    ),
                    password: Yup.string()
                      .required(
                        tLabels?.trial_noPassword_error_verifyAccountPage
                      )
                      .min(
                        8,
                        tLabels?.trial_passwordShort_error_verifyAccountPage
                      )
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                        tLabels?.trial_password_error_verifyAccountPage
                      ),
                    confirmPassword: Yup.string().oneOf(
                      [Yup.ref("password"), null],
                      tLabels?.trial_passwordMatch_error_verifyAccountPage
                    ),
                    country: Yup.string().required(
                      tLabels?.trial_countryReq_wizardPage
                    ),
                    state: Yup.string().when("country", {
                      is: (country) =>
                        states.filter((e) => e.country === country).length > 0,
                      then: Yup.string().required(
                        tLabels?.trial_stateReq_wizardPage
                      ),
                      otherwise: Yup.string(),
                    }),
                    phoneNumber: Yup.string()
                      .matches(
                        /^[0-9+() -]+$/,
                        tLabels?.trial_phone_type_req_wizardPage
                      )
                      .min(7, tLabels?.trial_phone_length_req_wizardPage)
                      .max(32, tLabels?.trial_phone_length_req_wizardPage),
                    extension: Yup.string()
                      .matches(
                        /^[0-9]+$/,
                        tLabels?.trial_extension_type_req_wizardPage
                      )
                      .min(1, tLabels?.trial_extension_length_req_wizardPage)
                      .max(10, tLabels?.trial_extension_length_req_wizardPage),
                  })}
                  onSubmit={async (values) => {
                    let acc = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      displayName: values.blueBeamDisplayName,
                      password: values.password,
                      country: values.country,
                      state: values.state,
                      language: values.userLanguage,
                      jobRole: values.jobRole,
                      title: values.title,
                      phone: values.phoneNumber,
                      extension: values.extension,
                      salesforceId: initialValues.salesforceId,
                      needPasswordReset: account.needPasswordReset,
                    };
                    next(acc);
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                    touched,
                    values,
                  }) => (
                    <Form autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                          <label
                            style={{
                              display: "block",
                              fontWeight: "500",
                              fontSize: 16,
                            }}
                          >
                            {tLabels?.trial_firstName_verifyAccountPage}
                          </label>
                          <TextField
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                            fullWidth
                            required
                            helperText={touched.firstName && errors.firstName}
                            name="firstName"
                            className="InputElem"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            autoComplete="off"
                            value={values.firstName}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label
                            style={{
                              display: "block",
                              fontWeight: "500",
                              fontSize: 16,
                            }}
                          >
                            {tLabels?.trial_lastName_verifyAccountPage}
                          </label>
                          <TextField
                            error={Boolean(touched.lastName && errors.lastName)}
                            fullWidth
                            required
                            helperText={touched.lastName && errors.lastName}
                            name="lastName"
                            className="InputElem"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            autoComplete="off"
                            value={values.lastName}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label
                            style={{
                              display: "block",
                              fontWeight: "500",
                              fontSize: 16,
                            }}
                          >
                            {tLabels?.trial_displayName_verifyAccountPage}
                          </label>
                          <TextField
                            error={Boolean(
                              touched.blueBeamDisplayName &&
                                errors.blueBeamDisplayName
                            )}
                            fullWidth
                            required
                            helperText={
                              touched.blueBeamDisplayName &&
                              errors.blueBeamDisplayName
                            }
                            name="blueBeamDisplayName"
                            className="InputElem"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            autoComplete="off"
                            value={values.blueBeamDisplayName}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label
                            style={{
                              display: "block",
                              marginTop: 5,
                              fontWeight: "500",
                              fontSize: 16,
                            }}
                          >
                            {tLabels?.trial_password_verifyAccountPage}
                          </label>
                          <label
                            style={{
                              display: "block",
                              marginTop: 5,
                              fontSize: 12,
                            }}
                            className="smlabel"
                          >
                            {" "}
                            {tLabels?.trial_passwordDesc_verifyAccountPage}
                          </label>
                          <TextField
                            error={Boolean(touched.password && errors.password)}
                            fullWidth
                            required
                            helperText={touched.password && errors.password}
                            name="password"
                            className="InputElem"
                            autoComplete="new-password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.password}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label
                            style={{
                              display: "block",
                              marginTop: 5,
                              fontWeight: "500",
                            }}
                          >
                            {tLabels?.trial_confirmPassword_verifyAccountPage}
                          </label>
                          <TextField
                            error={Boolean(
                              touched.confirmPassword && errors.confirmPassword
                            )}
                            fullWidth
                            helperText={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            name="confirmPassword"
                            autoComplete="off"
                            className="InputElem"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.confirmPassword}
                            variant="outlined"
                            required
                          />
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          md={12}
                          style={{
                            marginRight: 20,
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              color: "",
                              fontSize: 20,
                              fontWeight: 900,
                              marginLeft: 15,
                            }}
                          >
                            {tLabels?.trial_heading_contact_information}
                          </Typography>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <label style={{ display: "block" }}>
                            {tLabels?.trial_country_wizardPage}
                          </label>
                          <FormControl fullWidth className="InputElem">
                            <Select
                              labelId="country"
                              id="country-select"
                              name="country"
                              variant="outlined"
                              required
                              value={values.country}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.country && errors.country)}
                              // helperText={touched.country && errors.country}
                            >
                              {countries.map((country) => {
                                return (
                                  <MenuItem key={country} value={country}>
                                    {country}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <StateSelect
                            statesByCountry={states}
                            labels={tLabels}
                          ></StateSelect>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <label style={{ display: "block" }}>
                            {tLabels?.trial_language_verifyAccountPage}
                          </label>
                          <FormControl fullWidth className="InputElem">
                            <Select
                              labelId="userLanguage"
                              id="language-select"
                              variant="outlined"
                              name="userLanguage"
                              value={values.userLanguage}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.userLanguage && errors.userLanguage
                              )}
                            >
                              {languages.map((language) => {
                                return (
                                  <MenuItem key={language} value={language}>
                                    {language}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <label style={{ display: "block" }}>
                            {tLabels?.trial_jobLevel_wizardPage}
                          </label>
                          <FormControl fullWidth className="InputElem">
                            {/* <InputLabel>{tLabels?.trial_jobLevel_wizardPage}</InputLabel> */}
                            <Select
                              labelId="jobRole"
                              id="jobRole-select"
                              name="jobRole"
                              variant="outlined"
                              value={values.jobRole}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(touched.jobRole && errors.jobRole)}
                            >
                              {jobRoles.map((jobRole) => {
                                return (
                                  <MenuItem key={jobRole} value={jobRole}>
                                    {jobRole}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* new fields */}

                        <Grid item md={12} xs={12}>
                          <label style={{ display: "block" }}>
                            {tLabels?.trial_title_wizardPage}
                          </label>
                          <TextField
                            //error={Boolean(touched.title && errors.title)}
                            fullWidth
                            helperText={touched.title && errors.title}
                            name="title"
                            className="InputElem"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            value={values.title}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <label style={{ display: "block" }}>
                            {tLabels?.trial_phoneNumber_wizardPage}
                          </label>
                          <TextField
                            error={Boolean(
                              touched.phoneNumber && errors.phoneNumber
                            )}
                            fullWidth
                            helperText={
                              touched.phoneNumber && errors.phoneNumber
                            }
                            name="phoneNumber"
                            className="InputElem"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            value={values.phoneNumber}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <label style={{ display: "block" }}>
                            {tLabels?.trial_extension_wizardPage}
                          </label>
                          <TextField
                            error={Boolean(
                              touched.extension && errors.extension
                            )}
                            fullWidth
                            helperText={touched.extension && errors.extension}
                            name="extension"
                            className="InputElem"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            value={values.extension}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Box pt={2}>
                        {isError ? (
                          <Typography component="h6" color="error" variant="p">
                            {errorMsg}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      <div
                        style={{
                          marginTop: 20,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={Boolean(!isValid)}
                          style={{
                            height: 55,
                            padding: "17px 48px",
                            width: 170,
                            marginBottom: 30,
                            textTransform: "none",
                            boxShadow: "none",
                            background: "#0c8cdc",
                          }}
                        >
                          {loading && (
                            <CircularProgress
                              size={14}
                              style={isValid ? { color: "white" } : {}}
                            />
                          )}
                          <span style={isValid ? { color: "white" } : {}}>
                            {!loading && tLabels?.trial_btn_verifyAccountPage}
                          </span>
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default RegisterPage;
