import { apiAddress } from '../constants';
import axios from 'axios';

export default async function resendInvitationEmail(email) {
	try {
		const json = JSON.stringify({ email: email });

		const request = await axios.post(`${apiAddress}/EmailResend`, json);

		return { status: request.status, data: request.data };
	} catch (e) {
		console.log('📌 ~ resendInvitationEmail ~ e:', e);
		return { status: e.response.status, data: e.response.data.message };
	}
}
