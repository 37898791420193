import { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import resendInvitationEmail from "../../API/methods/resendInvitationEmail";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  TextField,
  FormControl,
  Button,
  CircularProgress,
  Typography,
  Backdrop,
} from "@material-ui/core";
import AppContext from "../../Context/appContext";
import useTranslations from "../../Components/useTranslations";
import logo from "../../Assets/concepts_commun.png";
import logo_sent from "../../Assets/checkMark.svg";
import "./index.css";

const schema = yup.object().shape({
  email: yup.string().email("Not a proper email"),
});

export default function ResendInvitationPage() {
  const appContext = useContext(AppContext);
  const { appState } = appContext;
  const tLabels = useTranslations(appState);
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState(false);
  const errorMsg =
    tLabels?.trial_server_error_wizardPage || "Error - Something went wrong";

  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    setEnableSubmit(false);
    setError(false);
    if (email === "") return;

    setLoadingSubmit(true);
    let response = await resendInvitationEmail(email);

    if (response.status !== 200) {
      console.log("📌 ~ ERROR handleSubmit ~ response:", response);
      setEnableSubmit(true);
      setError(true);
    } else {
      console.log("handleSubmit ~ response:", response);
      setEmail("");
      setIsSent(true);
    }
    setLoadingSubmit(false);
  }

  const validateEmail = useCallback(() => {
    try {
      return schema.validateSync({
        email,
      });
    } catch (error) {}
    return false;
  }, [email]);

  useEffect(() => {
    setLoading(() => tLabels?.trial_resendinvitation_title === undefined);
    setEnableSubmit(email ? validateEmail() : false);
  }, [email, validateEmail, tLabels]);

  const btnStyleBack = {
    borderColor: "#e0e0e0",
    textTransform: "none",
    boxShadow: "none",
    color: "hsl(203.08deg 89.66% 45.49%)",
  };

  const SubmitButton = withStyles(() => ({
    root: {
      backgroundColor: "hsl(203.08deg 89.66% 45.49%)",
      textTransform: "none",
      color: "white",
      "&:hover": {
        backgroundColor: "hsl(203.08deg 89.66% 45.49% / 75%)",
      },
    },
  }))(Button);

  const SupportLink = () => (
    <div className="block-center" style={{ flexDirection: "column", gap: 0 }}>
      <Typography variant="body1">
        {isSent
          ? tLabels?.trial_resendinvitation_support_info_sent
          : tLabels?.trial_resendinvitation_support_info}
      </Typography>

      <Button
        variant="text"
        onClick={() =>
          (window.location.href = tLabels?.trial_resendinvitation_support_url)
        }
      >
        <Typography
          style={{
            color: "#0c8cdc",
            textTransform: "none",
          }}
        >
          {tLabels?.trial_resendinvitation_support_link}
        </Typography>
      </Button>
    </div>
  );

  const SentPage = () => (
    <div className="new-email-form">
      <img src={logo_sent} alt="placeholder" className="form-logo" />
      <Typography
        variant="body1"
        style={{
          margin: ".5rem 0",
          textAlign: "center",
        }}
      >
        {tLabels?.trial_resendinvitation_body_sent}
      </Typography>
    </div>
  );

  if (loading === true) {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Paper elevation={0} className="page-wrapper">
      <Box
        style={{
          padding: "2rem",
          maxWidth: "34rem",
          border: "2px solid",
          margin: ".5rem",
        }}
      >
        {isSent === false ? (
          <form onSubmit={handleSubmit} className="new-email-form">
            <img src={logo} alt="placeholder" className="form-logo" />
            <Typography variant="h3" className="block-center title">
              {tLabels?.trial_resendinvitation_title}
            </Typography>
            <Typography
              variant="body1"
              style={{
                margin: "1rem 0",
                textAlign: "center",
              }}
            >
              {tLabels?.trial_resendinvitation_body}
            </Typography>
            <FormControl fullWidth>
              <label
                style={{ display: "block", margin: ".5rem 0", fontSize: 14 }}
              >
                {tLabels?.trial_resendinvitation_email}
              </label>
              <TextField
                type="email"
                fullWidth
                required
                variant="outlined"
                placeholder="emailaddress@company.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                autoComplete="off"
              />
            </FormControl>
            {error && <Typography color="error">{errorMsg}</Typography>}

            <div className="block-center">
              <Button
                type="button"
                onClick={() => navigate(-1)}
                variant="outlined"
                style={btnStyleBack}
                disableElevation
              >
                {tLabels?.trial_resendinvitation_btn_back}
              </Button>
              <SubmitButton
                type="submit"
                disabled={!enableSubmit}
                variant="contained"
                disableElevation
              >
                {loadingSubmit && (
                  <div style={{ paddingRight: ".5rem", display: "flex" }}>
                    <CircularProgress size={14} style={{ color: "white" }} />
                  </div>
                )}
                {tLabels?.trial_resendinvitation_btn_send}
              </SubmitButton>
            </div>
            <SupportLink />
          </form>
        ) : (
          <SentPage />
        )}
      </Box>
    </Paper>
  );
}
