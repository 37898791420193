import { apiAddress } from "../constants";
import axios from "axios";

export default async function verifyAccount(token) {
  try {
    const json = JSON.stringify({ verificationToken: token });

    const request = await axios.post(
      `${apiAddress}/GetVerificationDetails`,
      json
    );
    return { status: request.status, data: request.data };
  } catch (e) {
    console.log("error verifying account");
    return { status: e.status, data: "" };
  }
}
