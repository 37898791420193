import React, { useReducer } from "react";
import AppReducer from "./appReducer";
import AppContext from "./appContext";

// Creating AppState component for wrapping around App.
const AppState = ({ children }) => {
  const awsRegion = process.env.REACT_APP_AWS_REGION || "us-east-1";

  let lang =
    localStorage.getItem("language") !== null
      ? localStorage.getItem("language")
      : { label: "English", value: "english", key: 0 };

  if (awsRegion === "eu-central-1") {
    lang =
      localStorage.getItem("language") !== null
        ? localStorage.getItem("language")
        : { label: "Deutsch", value: "german", key: 1 };
  }

  if (awsRegion === "eu-north-1") {
    lang =
      localStorage.getItem("language") !== null
        ? localStorage.getItem("language")
        : { label: "Svenska", value: "swedish", key: 2 };
  }
  // initial state
  const initialState = {
    accountForm: {
      isFormProcessStarted: false, // we set to true after user has been validated and we prevent them from going into wizard directly by url hacking
      optIn: false,
      firstName: "",
      lastName: "",
      email: "",
      name: "",
      password: "",
      companyName: "",
      country: "",
      language: "",
      phone: "",
      phoneExt: "",
      countryCode: "",
      numberOfEmployees: "",
      industry: "",
      jobRole: "",
      purchasePlanDesc: "",
      source: "Website",
      state: "",
      sessionId: "",
      isFormComplete: false,
      salesforceId: "",
      isLoginAndConsent: false,
      awsregion: "",
      region: "",
    },
    countries: [],
    languages: [],
    industries: [],
    jobRoles: [],
    timings: [],
    employees: [],
    states: [],
    loading: false,
    isAuthenticated: false,
    language: lang, // default
    isTranslationsFetched: false, //
  };

  // Connecting the reducer to state.
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // action for setting initial account information
  const updateAccountInfo = (value) => {
    dispatch({
      type: "UPDATE_FORM",
      payload: value,
    });
  };

  const updateLanguageCountry = (value) => {
    dispatch({
      type: "UPDATE_COUNTRYLANGUAGE",
      payload: value,
    });
  };

  const changeLanguage = (value) => {
    dispatch({
      type: "UPDATE_LANGUAGE",
      payload: value,
    });
  };

  const updateIsTranslationsFetched = (value) => {
    dispatch({
      type: "UPDATE_TRANSLATIONS",
      payload: value,
    });
  };

  const setLoading = (value) => {
    dispatch({
      type: "SET_LOADING",
      payload: value,
    });
  };

  // Returning WalletState wrapper component which will give it's children components access to its state and actions.
  return (
    <AppContext.Provider
      value={{
        appState: state,
        loading: state.loading,
        updateAccountInfo,
        changeLanguage,
        updateLanguageCountry,
        setLoading,
        updateIsTranslationsFetched,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppState;
