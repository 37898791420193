import { apiAddress } from "../constants";
import axios from "axios";

export default async function selfRegistration(account, token) {
  try {
    const json = JSON.stringify({
      firstName: account.firstName,
      lastName: account.lastName,
      name: account.displayName,
      password: account.password,
      verificationToken: token,
      needPasswordReset: account.needPasswordReset
    });

    const request = await axios.post(`${apiAddress}/VerifyAccount`, json);
    return { status: request.status, data: request.data };
  } catch (e) {
    console.log("error in selfRegistration :" + e);
    return { status: e.status, data: "" };
  }
}
