import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import AppContext from "../../Context/appContext";
import checkMark from "../../Assets/checkMark.svg";
import useTranslations from "../../Components/useTranslations";

// RE: translations - see useTranslations note
const hardCodedCopy = {
  "trial_heading_registrationCompletedPage": "Thank you for signing up!",
  "trial_subheading_registrationCompleteSubheadingHeadline" : "What's next?",
  "trial_subheading_registrationCompleteBody1": "You'll receive confirmation by email shortly. Don't forget to check your spam folder. ",
  "trial_subheading_registrationCompleteBody2": "Your email address is your Bluebeam ID (BBID), which gives you access to Bluebeam Revu 21, Studio in Revu and Bluebeam Cloud - no product key or serial number is required. ",
  "trial_subheading_registrationCompleteImportant": "Important",
  "trial_subheading_registrationCompleteImportantNote1": "Please select",
  "trial_subheading_registrationCompleteImportantNote2": "as your server region when prompted.",
  "trial_subheading_registrationCompleteGettingStartedHeader": "Getting started:",
  "trial_subheading_registrationDownloadRevu1": "Download Revu 21 here",
  "trial_subheading_registrationDownloadRevu2": "You'll be prompted to log in with your BBID. ",
  "trial_subheading_registrationDownloadRevuURL": "https://app.bluebeam.com/ ",
  "trial_subheading_registrationAccess1": "Access Bluebeam Cloud on the web here",
  "trial_subheading_registrationAccess2": "You can download the iOS app in the App Store, or the Android app in the Google Play Store.",
  "trial_subheading_registrationAccessBluebeamCloudURL": "https://app.bluebeam.com/ ",
  "trial_heading_desc_registrationCompletedPage": "You may now close this window."
};

const MessageBody = (props) => {
  const { tLabels } = props;
  const REGION_NAME = process.env.REACT_APP_REGION_NAME || "United States";

  const getValue = (keyName) => {
    return tLabels[keyName] || hardCodedCopy[keyName];
  }

  return (
    <div>
      {/* what's next */}
      <Typography
        variant="h6"
        style={{
          margin: 20,
          color: "",
          fontSize: 22,
          fontWeight: 600,
        }}
      >
        {getValue('trial_subheading_registrationCompleteSubheadingHeadline')}
      </Typography>
      <Typography
        style={{
          margin: 20,
          color: "",
          fontSize: 16
        }}
      >
        {getValue('trial_subheading_registrationCompleteBody1')}
      </Typography>
      <Typography
        style={{
          margin: 20,
          color: "",
          fontSize: 16
        }}
      >
        {getValue('trial_subheading_registrationCompleteBody2')}
      </Typography>

      <Typography
        style={{
          marginTop: 40,
          margin: 20,
          color: "",
        }}
      >
        <strong>{`${getValue('trial_subheading_registrationCompleteImportant')}: `} </strong>
        {`${getValue('trial_subheading_registrationCompleteImportantNote1')}  `}
        <strong>{`[ ${REGION_NAME} ] `}</strong>
        {`${getValue('trial_subheading_registrationCompleteImportantNote2')}`}
      </Typography>


      <div style={{ marginBottom: 50 }} />

      {/* getting started */}
      <Typography
        variant="h6"
        style={{
          margin: 20,
          color: "",
          fontSize: 22,
          fontWeight: 600
        }}
      >
        {getValue('trial_subheading_registrationCompleteGettingStartedHeader')}
      </Typography>
      <ul>
        <li>
          <Typography
            style={{
              margin: 20,
              color: "",
              fontSize: 16,

            }}
          >
            <a href="https://www.bluebeam.com/download/"
              style={{
                color: "#0099DE",
                height: 20,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
            {`Download Revu 21 here`}
            </a>
            {". "}
            {` ${getValue('trial_subheading_registrationDownloadRevu2')}`}

          </Typography>
        </li>
        <li>
          <Typography
            style={{
              margin: 20,
              color: "",
              fontSize: 16,

            }}
          >
            <a href="https://www.bluebeam.com/download/"
              style={{
                color: "#0099DE",
                height: 20,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {`Access Bluebeam Cloud on the web here`}
            </a>
            {". "}
            {` ${getValue('trial_subheading_registrationAccess2')}`}

          </Typography>
        </li>
      </ul>
    </div>
  );
};

const VerifyEmailPage = () => {
  const appContext = useContext(AppContext);
  const { appState } = appContext;
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const tLabels = useTranslations(appState, "english");

  // Prevent direct URL navigation isFormComplete or isFormProcessStarted ?
  if (!appState.accountForm.isFormComplete) {
    //navigate("/");
  }

  return (
    <React.Fragment>
      {loading ? (
        ""
      ) : (
        <>
          <Grid
            container
            md={8}
            style={{
              margin: "0 auto",
              marginTop: 50,
            }}
          ></Grid>
          <Typography
            variant="h6"
            align="center"
            style={{
              marginBottom: 50,
              color: "",
              fontSize: 36,
              fontWeight: 900,
            }}
          >
            Thank you for signing up!
            {/* {tLabels?.trial_heading_registrationCompletedPage} */}
          </Typography>
          <Grid
            container
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
              border: "1px solid #DADADA",
              marginBottom: 100,
            }}
          >
            {" "}
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                padding: 20,
                marginBottom: 50,
              }}
            >
              <MessageBody tLabels={tLabels} />
              <span
                style={{
                  textAlign: "center",
                  color: "#000000",
                  margin: "0 auto",
                  display: "block",
                  marginTop: 57,
                }}
              >
                <img src={checkMark} alt="checkmark"></img>
              </span>
              <Typography
                variant="h6"
                align="center"
                style={{
                  marginTop: 30,
                  color: "",
                  fontSize: 22
                }}
              >
                {tLabels?.trial_heading_desc_registrationCompletedPage}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};

export default VerifyEmailPage;
