import { useEffect } from "react";
import { STATES_BY_COUNTRY } from "../API/constants";
import fetchLanguagesAndCountries from "../API/methods/fetchLanguagesAndCountries";

const usePicklistValues = (appState, updateLanguageCountry) => {
  useEffect(() => {
    (async () => {
      let response = await fetchLanguagesAndCountries();
      if (response) {
        updateLanguageCountry({
          ...appState,
          countries: response.countries,
          languages: response.languages,
          industries: response.industries,
          timings: response.timings,
          jobRoles: response.jobRoles,
          employees: response.employees,
          states: STATES_BY_COUNTRY,
        });
      } else {
        return false;
      }
    })();
  }, []);
  return true;
};

export default usePicklistValues;
