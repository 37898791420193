import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CookieBot from "react-cookiebot";
const domainGroupId = "cfa6650c-6e40-4203-ab39-6ed1d5f83a7a";

ReactDOM.render(
  <React.StrictMode>
    <>
      <CookieBot domainGroupId={domainGroupId} />
      <App />
    </>
  </React.StrictMode>,
  document.getElementById("root")
);
