import { apiAddress } from "../constants";
import axios from "axios";

export default async function preRegisterUser(state) {
  const langDict = {
    english: "en",
    norsk: "en",
    dansk: "en",
    deutsch: "de",
    svenska: "sv",
  };
  try {
    const json = JSON.stringify({
      firstName: state.firstName,
      lastName: state.lastName,
      name: state.name,
      password: state.password,
      sessionId: state.sessionId,
      language: langDict[state.language.toLowerCase()] || "",
      userType: "trial",
    });

    const request = await axios.post(`${apiAddress}/RegisterTrialUser`, json);
    return { status: request.status, data: request.data };
  } catch (e) {
    return { status: e.status, data: e.response?.data?.message };
  }
}
