import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IndexPage from "./Pages/IndexPage/index";
import DownloadPage from "./Pages/DownloadPage/index";
import WizardPage from "./Pages/WizardPage/index";
import VerifyAccountPage from "./Pages/RegisterPage/index";
import InvitePage from "./Pages/InvitePage/index";
import InvalidPage from "./Pages/InvalidPage/index";
import ResendInvitationPage from "./Pages/ResendInvitationPage/index";

import AppState from "./Context/appState";
import Navbar from "./Components/navbar";
import Footer from "./Components/footer";
import LoginCallback from "./Components/authorizeCallback";
import ConsentPage from "./Pages/ConsentPage";
import FinalizeLoginPage from "./Pages/FinalizeLoginPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppState>
        <Router>
          <div className="content-container">
            <Navbar />
            <Routes>
              <Route path="/" element={<IndexPage />}></Route>
              <Route path="/Download" element={<DownloadPage />}></Route>
              <Route path="/AccountCreation" element={<WizardPage />}></Route>
              <Route
                path="/VerifyAccount/:id"
                element={<VerifyAccountPage />}
              ></Route>
              <Route path="/ConsentPage" element={<ConsentPage />}></Route>
              <Route
                path="/resendinvitation"
                element={<ResendInvitationPage />}
              ></Route>
              <Route
                path="/RegistrationCompleted"
                element={<InvitePage />}
              ></Route>
              <Route path="/InvalidInvite" element={<InvalidPage />}></Route>
              <Route
                path="/authorize_callback"
                element={<LoginCallback />}
              ></Route>
              <Route
                path="/CompleteLogin/:id"
                element={<FinalizeLoginPage />}
              ></Route>
              <Route path="*" element={<IndexPage />}></Route>
            </Routes>
            <Footer />
          </div>
        </Router>
      </AppState>
    </ThemeProvider>
  );
}
