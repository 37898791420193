import { apiAddress } from "../constants";
import axios from "axios";

// returns languages + labels for language switcher
export default async function fetchTranslations() {
  try {
    let res = await axios.get(`${apiAddress}/GetTrialLanguages`);
    return res.data;
  } catch (e) {
    console.log("error fetching languages");
    console.log(e);
  }
}
