import React, { useEffect, useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Grid, Typography, Button, Paper } from "@mui/material";
import AppContext from "../../Context/appContext";
import styling from "../DownloadPage/index.css";
import cloud from "../../Assets/interface_ui-cl.png";
import letter from "../../Assets/concepts_commun.png";
import device from "../../Assets/objects_devices.png";
import useTranslations from "../../Components/useTranslations";

const DOWNLOAD_URL =
  process.env.REACT_APP_DOWNLOAD_URL || "https://bluebeam.com/InstRevuTRIAL";

const REGION_NAME = process.env.REACT_APP_REGION_NAME || "United States";

const DownloadPage = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { appState } = appContext;
  const [loading, setIsLoading] = useState(false);

  const tLabels = useTranslations(appState);

  useEffect(() => {
    if (!appState.accountForm.isFormComplete) {
      navigate("/");
    } else {
      // call download here so that it is executed on page load
      handleDownload();
    }
  }, []);

  const handleDownload = () => {
    window.open(DOWNLOAD_URL, "_blank");
  };
  return (
    <div className="DownloadPage">
      {loading ? (
        ""
      ) : (
        <>
          <Grid
            container
            md={9}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
              maxWidth: 840,
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: 20, letterSpacing: -0.8 }}
              justifyContent="center"
            >
              <Typography
                variant="h4"
                align="center"
                fontSize={50}
                fontWeight={700}
              >
                {tLabels?.trial_heading_downloadPage}
              </Typography>
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                  marginBottom: 20,
                  wordWrap: "break-word",
                }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={16}
                  style={{
                    width: "60%",
                    margin: "0 auto",
                  }}
                >
                  {tLabels?.trial_heading_desc_1_downloadPage}
                  <b>{tLabels?.trial_heading_desc_2_downloadPage}</b>
                </Typography>
              </div>

              <Grid
                item
                xs={12}
                md={12}
                style={{
                  backgroundColor: "#f4f4f4",
                  padding: 30,
                  marginBottom: 30,
                }}
              >
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <img src={device} alt="" />
                </div>

                <Typography
                  variant="h4"
                  align="center"
                  fontSize={24}
                  fontWeight={700}
                >
                  {tLabels?.trial_desktop_1_downloadPage}
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={16}
                  style={{ marginTop: 20 }}
                >
                  {tLabels?.trial_desktop_2_downloadPage}
                  <a
                    style={{ color: "#1b8fde" }}
                    target="_blank"
                    rel="noreferrer"
                    href={tLabels?.trial_desktop3_url_downloadpage}
                  >
                    {tLabels?.trial_desktop_3_downloadPage}
                  </a>

                  {tLabels?.trial_desktop_4_downloadPage}
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleDownload}
                  style={{
                    background: "#0884dc",
                    color: "white",
                    display: "flex",
                    margin: "0 auto",
                    height: 75,
                    marginTop: 30,
                    marginBottom: 20,
                    width: 350,
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: 15,
                    boxShadow: "none",
                  }}
                >
                  {tLabels?.trial_button_downloadPage}
                </Button>
                <Typography
                  variant="body1"
                  align="center"
                  fontSize={16}
                  style={{ marginTop: 20 }}
                >
                  {tLabels?.trial_downloadpage_regionInfo1}
                  <b style={{whiteSpace: "nowrap"}}>{REGION_NAME}</b>
                  {tLabels?.trial_downloadpage_regionInfo2}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ backgroundColor: "#f4f4f4", padding: 30 }}
                justifyContent="center"
              >
                <div style={{ width: "100%", marginBottom: 20 }}>
                  <div style={{ textAlign: "center", marginBottom: 5 }}>
                    <img src={cloud} alt="" />
                  </div>
                  <Typography
                    variant="body1"
                    align="center"
                    fontSize={16}
                    style={{
                      width: "60%",
                      margin: "0 auto",
                    }}
                  >
                    <b>{tLabels?.trial_cloud_1_downloadPage} </b>
                    {tLabels?.trial_cloud_2_downloadPage}
                  </Typography>
                  <div
                    style={{
                      width: "85%",
                      margin: "0 auto",
                      marginTop: 20,
                      textAlign: "center",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={tLabels?.trial_cloud_3_url_downloadPage}
                      style={{ color: "#0083db", fontWeight: "bold" }}
                    >
                      <b>{tLabels?.trial_cloud_3_urltext_downloadPage}</b>
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    width: "75%",
                    margin: "0 auto",
                    marginTop: 30,
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", gap: 15 }}>
                    <img src={letter} alt="" />
                    <Typography
                      variant="body1"
                      fontSize={15}
                      justifyContent="center"
                      style={{ textAlign: "left" }}
                    >
                      {tLabels?.trial_uni_1_downloadPage}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default DownloadPage;
