import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../Assets/bb-logo.png";
import AppContext from "../Context/appContext";
import useTranslations from "./useTranslations";

const Footer = () => {
  const appContext = useContext(AppContext);
  const { appState } = appContext;
  const { pathname } = useLocation();

  const tLabels = useTranslations(appState);

  return (
    <>
      {pathname !== "/authorize_callback" ? (
        <div
          style={{
            width: "100%",
            height: 120,
            position: "relative",
            bottom: 0,
            left: 0,
            marginTop: 40,
            backgroundColor: "#081c44",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <img
              style={{ marginLeft: 50, marginRight: 20 }}
              src={logo}
              alt=""
            ></img>
            <div
              style={{
                display: "flex",
                gap: 10,
                color: "#ffffff",
                fontSize: 12,
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <div style={{ maxWidth: 840 }}>
                <Typography variant="body1" fontSize={12}>
                  <a
                    href={tLabels?.trial_footer_link1}
                    rel="noreferrer"
                    style={{
                      color: "#ffffff",
                      textDecoration: "none",
                      marginRight: 10,
                    }}
                  >
                    {tLabels?.trial_footer_link1_text}
                  </a>
                  |
                  <a
                    href={tLabels?.trial_footer_link2}
                    rel="noreferrer"
                    style={{
                      color: "#ffffff",
                      textDecoration: "none",
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  >
                    {tLabels?.trial_footer_link2_text}
                  </a>
                  |
                  <a
                    href={tLabels?.trial_footer_link3}
                    rel="noreferrer"
                    style={{
                      color: "#ffffff",
                      textDecoration: "none",
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  >
                    {tLabels?.trial_footer_link3_text}
                  </a>
                  |
                  <a
                    href={tLabels?.trial_footer_link4}
                    rel="noreferrer"
                    style={{
                      color: "#ffffff",
                      textDecoration: "none",
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  >
                    {tLabels?.trial_footer_link4_text}
                  </a>
                  |
                  <a
                    href={"javascript: Cookiebot.renew()"}
                    rel="noreferrer"
                    style={{
                      color: "#ffffff",
                      textDecoration: "none",
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  >
                    {tLabels?.trial_footer_link5_text}
                  </a>
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={12}
                  style={{ marginTop: 5 }}
                >
                  {tLabels?.trial_footer_clause}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Footer;
