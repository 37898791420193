const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FORM":
      return {
        ...state,
        accountForm: action.payload,
      };
    case "UPDATE_COUNTRYLANGUAGE":
      return {
        ...state,
        countries: action.payload.countries,
        languages: action.payload.languages,
        industries: action.payload.industries,
        states: action.payload.states,
        jobRoles: action.payload.jobRoles,
        timings: action.payload.timings,
        employees: action.payload.employees,
      };
    case "UPDATE_LANGUAGE":
      return {
        ...state,
        language: action.payload.language,
      };
    case "UPDATE_TRANSLATIONS":
      return {
        ...state,
        isTranslationsFetched: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
