import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/appContext";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Bluebeam-Logo.png";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import storeDownloadInfo from "../../API/methods/storeDownloadInfo";
import useTranslations from "../../Components/useTranslations";
import usePicklistValues from "../../Components/usePicklistValues";
import { Tooltip } from "@mui/material";
import styling from "../ConsentPage/index.css";
import StateSelect from "../../Components/stateSelect";
import { countryCodes } from "../../API/constants";
import flagCSS from "../../Assets/flags.css";

const ConsentPage = () => {
  const appContext = useContext(AppContext);
  const { appState, updateAccountInfo, updateLanguageCountry } = appContext;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMsg] = useState("Error - Something went wrong");

  const handleApprove = (newState) => {
    setLoading(true);
    //appState.accountForm.salesforceId === ""
    handleNewLeadComplete(newState);
    //: handleExistingLeadComplete(newState);
  };

  useEffect(() => {
    if (!appState.accountForm.isLoginAndConsent) {
      navigate("/");
    }
  }, []);
  const tLabels = useTranslations(appState);
  usePicklistValues(appState, updateLanguageCountry);

  const handleCompleteResponse = async (response) => {
    if (response.data.status === "SUCCESS") {
      setLoading(false);
      updateAccountInfo({
        ...appState.accountForm,
        isFormProcessStarted: false, //we reset after form submission so user cant come back here
        optIn: false,
        isFormComplete: true,
        isLoginAndConsent: false,
      });
      navigate("/Download");
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const handleExistingLeadComplete = async () => {
    let response = await storeDownloadInfo({
      purchasePlanDesc: appState.accountForm.purchasePlanDesc,
      optIn: appState.accountForm.optIn,
      sessionId: appState.accountForm.sessionId,
    });
    await handleCompleteResponse(response);
  };

  const handleNewLeadComplete = async (newState) => {
    let response = await storeDownloadInfo(newState);
    await handleCompleteResponse(response);
  };

  return (
    <React.Fragment>
      <Box m={10}>
        <Grid
          container
          style={{
            display: "flex",
            maxWidth: "1280px",
            justifyContent: "center",
            margin: "0 auto",
            border: "1px solid #DADADA",
            marginBottom: 50,
            marginTop: 50,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              borderBottom: "1px solid #DADADA",
              padding: 20,
            }}
          >
            {/* {appState.accountForm.salesforceId === "" ? ( */}
            <NewLeadForm
              handleApprove={handleApprove}
              loading={loading}
              isError={isError}
              errorMsg={errorMsg}
              tLabels={tLabels}
              updateAccountInfo={updateAccountInfo}
            ></NewLeadForm>
            {/* ) : (
              <ExistingLeadForm
                handleApprove={handleApprove}
                loading={loading}
                isError={isError}
                errorMsg={errorMsg}
                tLabels={tLabels}
                updateAccountInfo={updateAccountInfo}
              ></ExistingLeadForm>
            )} */}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

const ConsentForm = ({
  tLabels,
  touched,
  errors,
  handleBlur,
  handleChange,
  values,
  timings,
}) => {
  return (
    <>
      <Grid item md={12} xs={12}>
        <label
          style={{
            display: "block",
            fontWeight: "500",
          }}
        >
          {tLabels?.trial_revu_download_purchasePlan_indexPage}
        </label>

        <FormControl fullWidth className="InputElem">
          <Select
            labelId="purchasePlanDesc"
            id="purchasePlanDesc-select"
            name="purchasePlanDesc"
            required
            variant="outlined"
            displayEmpty
            value={values.purchasePlanDesc}
            onBlur={handleBlur}
            onChange={handleChange}
            renderValue={(values) =>
              values ? (
                values
              ) : (
                <div style={{ color: "#aaa" }}>
                  {tLabels?.trial_select_placeholder}
                </div>
              )
            }
            error={Boolean(touched.purchasePlanDesc && errors.purchasePlanDesc)}
            // helperText={touched.industry && errors.industry}
          >
            {timings.map((plan) => {
              return (
                <MenuItem key={plan} value={plan}>
                  {plan}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={12} xs={12}>
        <FormGroup style={{ marginBottom: 5, marginTop: 5 }}>
          <FormControlLabel
            control={
              <Checkbox
                id="checked"
                name="checked"
                checked={values.checked}
                onChange={handleChange}
              />
            }
            label={tLabels?.trial_revu_download_allowEmail_indexPage}
          />
        </FormGroup>
      </Grid>
      <Grid item md={12} xs={12}>
        <FormGroup style={{ marginBottom: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                id="consentChecked"
                name="consentChecked"
                checked={values.consentChecked}
                error={Boolean(touched.consentChecked && errors.consentChecked)}
                onChange={handleChange}
              />
            }
            label={
              <Typography variant="body1">
                <b>{tLabels?.trial_revu_download_privacy_policy_section1}</b>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={tLabels?.trial_revu_download_privacy_link1}
                >
                  {tLabels?.trial_revu_download_privacy_link1_text}
                </a>
                <b>{tLabels?.trial_revu_download_privacy_policy_section4}</b>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={tLabels?.trial_revu_download_privacy_link2}
                >
                  {tLabels?.trial_revu_download_privacy_link2_text}
                </a>
                <b>{tLabels?.trial_revu_download_privacy_policy_section2}</b>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={tLabels?.trial_revu_download_privacy_link3}
                >
                  {tLabels?.trial_revu_download_pricacy_link3_text}
                </a>

                <b>{tLabels?.trial_revu_download_privacy_policy_section3}</b>
                <Tooltip
                  title={
                    <span style={{ fontSize: 15 }}>
                      {tLabels?.trial_revu_download_whatispartner}
                    </span>
                  }
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={tLabels?.trial_revu_download_whatispartnerlink}
                  >
                    {tLabels?.trial_revu_download_whatispartnertext}
                  </a>
                </Tooltip>

                <b>{tLabels?.trial_revu_download_privacy_policy_section5}</b>
              </Typography>
            }
          />
        </FormGroup>
      </Grid>
    </>
  );
};

const SubmitForm = ({
  isError,
  errorMsg,
  navigate,
  isValid,
  loading,
  tLabels,
}) => {
  return (
    <>
      <Box pt={2}>
        {isError ? (
          <Typography component="h6" color="error" variant="p">
            {errorMsg}
          </Typography>
        ) : (
          ""
        )}
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => navigate("/")}
          style={{
            height: 55,
            padding: "17px 48px",
            width: 200,
            textTransform: "none",
            boxShadow: "none",
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={Boolean(!isValid)}
          style={{
            height: 55,
            marginLeft: "auto",
            padding: "17px 48px",
            width: 200,
            textTransform: "none",
            boxShadow: "none",
            background: "#0c8cdc",
          }}
        >
          {loading && (
            <CircularProgress
              size={14}
              style={isValid ? { color: "white" } : {}}
            />
          )}
          <span style={isValid ? { color: "white" } : {}}>
            {!loading && tLabels?.trial_completeBtn_wizardPage}
          </span>
        </Button>
      </div>
    </>
  );
};

const ExistingLeadForm = ({
  handleApprove,
  isError,
  errorMsg,
  loading,
  tLabels,
  updateAccountInfo,
}) => {
  const appContext = useContext(AppContext);
  const { appState } = appContext;

  const { timings } = appState;

  const initialValues = {
    purchasePlanDesc: appState.accountForm.purchasePlanDesc || "",
  };

  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      validationSchema={Yup.object().shape({
        purchasePlanDesc: Yup.string()
          .ensure()
          .required(tLabels?.trial_purchasePlanReq_wizardPage),
        consentChecked: Yup.bool().oneOf([true], "Must be checked"),
      })}
      onSubmit={async (values) => {
        updateAccountInfo({
          ...appState.accountForm,
          purchasePlanDesc: values.purchasePlanDesc,
          optIn: values.checked,
        });
        handleApprove({
          ...appState.accountForm,
          purchasePlanDesc: values.purchasePlanDesc,
          optIn: values.checked,
        });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
        touched,
        values,
      }) => (
        <Form autoComplete="off">
          <Typography variant="h6">
            {tLabels?.trial_finishAccCreation_wizardPage}
          </Typography>
          <Typography variant="body1">
            {tLabels?.trial_questions_wizardPage}
          </Typography>

          <div
            style={{
              background: "#F1F2F2",
              padding: 20,
              marginRight: -20,
              marginLeft: -20,
              borderBottom: "1px solid #DADADA",
              borderTop: "1px solid #DADADA",
              marginTop: 20,
            }}
          >
            <label
              style={{
                display: "block",
                marginBottom: 5,
              }}
            >
              {tLabels?.trial_revu_download_workEmail_indexPage}
            </label>
            <label
              style={{
                display: "block",
                marginBottom: 20,
              }}
            >
              {appState.accountForm.email}
            </label>
            <Grid container spacing={2}>
              <ConsentForm
                tLabels={tLabels}
                timings={timings}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
              ></ConsentForm>
            </Grid>
          </div>
          <SubmitForm
            isError={isError}
            errorMsg={errorMsg}
            navigate={navigate}
            isValid={isValid}
            loading={loading}
            tLabels={tLabels}
          ></SubmitForm>
        </Form>
      )}
    </Formik>
  );
};

const NewLeadForm = ({
  isError,
  errorMsg,
  loading,
  tLabels,
  handleApprove,
  updateAccountInfo,
}) => {
  const appContext = useContext(AppContext);
  const { appState } = appContext;

  const initialValues = {
    companyName: appState.accountForm.companyName || "",
    firstName: appState.accountForm.firstName || "",
    lastName: appState.accountForm.lastName || "",
    country: appState.accountForm.country || "",
    language: appState.accountForm.language || "",
    phone: appState.accountForm.phone || "",
    phoneExt: appState.accountForm.phoneExt || "",
    countryCode: appState.accountForm.countryCode || "",
    state: appState.accountForm.state || "",
    numberOfEmployees: appState.accountForm.numberOfEmployees || "",
    industry: appState.accountForm.industry,
    jobRole: appState.accountForm.jobRole || "",
    purchasePlanDesc: appState.accountForm.purchasePlanDesc || "",
    checked: false,
    consentChecked: false,
  };

  const {
    countries,
    states,
    languages,
    industries,
    employees,
    jobRoles,
    timings,
  } = appState;

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required(
          tLabels?.trial_firstNameReq_wizardPage
        ),
        lastName: Yup.string().required(tLabels?.trial_lastNameReq_wizardPage),
        purchasePlanDesc: Yup.string()
          .ensure()
          .required(tLabels?.trial_purchasePlanReq_wizardPage),
        companyName: Yup.string().required(
          tLabels?.trial_companyNameReq_wizardPage
        ),
        country: Yup.string()
          .ensure()
          .required(tLabels?.trial_countryReq_wizardPage),
        state: Yup.string().when("country", {
          is: (country) =>
            states.filter((e) => e.country === country).length > 0,
          then: Yup.string().required(tLabels?.trial_stateReq_wizardPage),
          otherwise: Yup.string(),
        }),
        language: Yup.string()
          .ensure()
          .required(tLabels?.trial_languageReq_wizardPage),
        numberOfEmployees: Yup.string()
          .ensure()
          .required(tLabels?.trial_numEmployeesReq_wizardPage),
        // phone: Yup.string()
        //   .required(
        //     tLabels?.trial_phoneNumber_wizardPage +
        //       " " +
        //       tLabels?.trial_required?.toLowerCase()
        //   )
        //   .matches(/^[0-9]+$/, tLabels?.trial_phone_type_req_wizardPage)
        //   .min(1, tLabels?.trial_phone_length_req_wizardPage)
        //   .max(40, tLabels?.trial_phone_length_req_wizardPage),
        // phoneExt: Yup.string()
        //   .matches(/^[0-9]+$/, tLabels?.trial_phone_type_req_wizardPage)
        //   .min(1, tLabels?.trial_phone_length_req_wizardPage)
        //   .max(10, tLabels?.trial_phone_length_req_wizardPage),
        // countryCode: Yup.string()
        //   .ensure()
        //   .required(tLabels?.trial_countryReq_wizardPage),
        industry: Yup.string().required(tLabels?.trial_industryReq_wizardPage),
        jobRole: Yup.string().required(tLabels?.trial_jobRoleReq_wizardPage),
        consentChecked: Yup.bool().oneOf([true], "Must be checked"),
      })}
      onSubmit={async (values) => {
        updateAccountInfo({
          ...appState.accountForm,
          name: values.firstName + " " + values.lastName,
          firstName: values.firstName,
          lastName: values.lastName,
          companyName: values.companyName,
          country: values.country,
          state: values.state,
          language: values.language,
          phone: values.phone,
          phoneExt: values.phoneExt,
          countryCode: values.countryCode,
          numberOfEmployees: values.numberOfEmployees,
          industry: values.industry,
          jobRole: values.jobRole,
          purchasePlanDesc: values.purchasePlanDesc,
          optIn: values.checked,
        });
        handleApprove({
          ...appState.accountForm,
          name: values.firstName + " " + values.lastName,
          firstName: values.firstName,
          lastName: values.lastName,
          companyName: values.companyName,
          country: values.country,
          state: values.state,
          language: values.language,
          phone: values.phone,
          phoneExt: values.phoneExt,
          countryCode: values.countryCode,
          numberOfEmployees: values.numberOfEmployees,
          industry: values.industry,
          jobRole: values.jobRole,
          purchasePlanDesc: values.purchasePlanDesc,
          optIn: values.checked,
        });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
        touched,
        values,
      }) => (
        <Form autoComplete="off">
          <Typography variant="h6">
            {tLabels?.trial_finishAccCreation_wizardPage}
          </Typography>
          <Typography variant="body1">
            {tLabels?.trial_questions_wizardPage}
          </Typography>

          <div
            style={{
              background: "#F1F2F2",
              padding: 20,
              marginRight: -20,
              marginLeft: -20,
              borderBottom: "1px solid #DADADA",
              borderTop: "1px solid #DADADA",
              marginTop: 20,
            }}
          >
            <label
              style={{
                display: "block",
                marginBottom: 20,
              }}
            >
              {appState.accountForm.email}
            </label>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <label
                  style={{
                    display: "block",
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  {tLabels?.trial_revu_download_firstName_indexPage}
                </label>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  required
                  helperText={touched.firstName && errors.firstName}
                  inputProps={{
                    style: {
                      // height: 50,
                    },
                  }}
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <label
                  style={{
                    display: "block",
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  {tLabels?.trial_revu_download_lastName_indexPage}
                </label>
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  required
                  helperText={touched.lastName && errors.lastName}
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.lastName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_companyName_wizardPage}
                </label>
                <TextField
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  helperText={touched.companyName && errors.companyName}
                  name="companyName"
                  className="InputElem"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.companyName}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_country_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="country"
                    id="country-select"
                    name="country"
                    required
                    displayEmpty
                    value={values.country}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    onBlur={handleBlur}
                    variant="outlined"
                    onChange={handleChange}
                    error={Boolean(touched.country && errors.country)}
                    // helperText={touched.country && errors.country}
                  >
                    {countries.map((country) => {
                      return (
                        <MenuItem key={country} value={country}>
                          {country}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <StateSelect
                  statesByCountry={states}
                  labels={tLabels}
                ></StateSelect>
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block", marginBottom: 10 }}>
                  {tLabels?.trial_language_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="language"
                    id="language-select"
                    name="language"
                    variant="outlined"
                    required
                    displayEmpty
                    value={values.language}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.language && errors.language)}
                    // helperText={touched.language && errors.language}
                  >
                    {languages.map((language) => {
                      return (
                        <MenuItem key={language} value={language}>
                          {language}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_numbEmployees_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="numberOfEmployees"
                    id="numberOfEmployees-select"
                    name="numberOfEmployees"
                    required
                    variant="outlined"
                    displayEmpty
                    value={values.numberOfEmployees}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(
                      touched.numberOfEmployees && errors.numberOfEmployees
                    )}
                  >
                    {employees.map((employee) => {
                      return (
                        <MenuItem key={employee} value={employee}>
                          {employee}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_industry_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="industry"
                    id="industry-select"
                    name="industry"
                    required
                    variant="outlined"
                    displayEmpty
                    value={values.industry}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    error={Boolean(touched.industry && errors.industry)}
                    // helperText={touched.industry && errors.industry}
                  >
                    {industries.map((industry) => {
                      return (
                        <MenuItem key={industry} value={industry}>
                          {industry}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_jobRole_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="jobRole"
                    id="jobRole-select"
                    name="jobRole"
                    required
                    variant="outlined"
                    displayEmpty
                    value={values.jobRole}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    error={Boolean(touched.jobRole && errors.jobRole)}
                    // helperText={touched.industry && errors.industry}
                  >
                    {jobRoles.map((role) => {
                      return (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item md={2} xs={2} style={{ minWidth: "140px" }}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_country_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    error={Boolean(touched.countryCode && errors.countryCode)}
                    fullWidth
                    helperText={touched.countryCode && errors.countryCode}
                    name="countryCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    renderValue={(selectedValue) => {
                      return (
                        <div>
                          <i
                            className={"flag " + selectedValue.toLowerCase()}
                          />
                          &nbsp;
                          {
                            countryCodes.find((x) => x.code === selectedValue)
                              .number
                          }
                        </div>
                      );
                    }}
                    type="text"
                    value={values.countryCode}
                    variant="outlined"
                    required
                  >
                    {countryCodes.map((country) => {
                      return (
                        <MenuItem key={country.code} value={country.code}>
                          <i className={"flag " + country.code.toLowerCase()} />
                          &nbsp;
                          {country.name + " " + country.number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={true} xs={true}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_phoneNumber_wizardPage?.slice(-1) === "*"
                    ? tLabels?.trial_phoneNumber_wizardPage
                    : tLabels?.trial_phoneNumber_wizardPage + "*"}
                </label>
                <FormControl fullWidth className="InputElem">
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    name="phone"
                    className="InputElem"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={2} xs={2}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_extension_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <TextField
                    error={Boolean(touched.phoneExt && errors.phoneExt)}
                    fullWidth
                    helperText={touched.phoneExt && errors.phoneExt}
                    name="phoneExt"
                    className="InputElem"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.phoneExt}
                    variant="outlined"
                  />
                </FormControl>
              </Grid> */}
              <ConsentForm
                tLabels={tLabels}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
                timings={timings}
              ></ConsentForm>
            </Grid>
          </div>
          <SubmitForm
            isError={isError}
            errorMsg={errorMsg}
            navigate={navigate}
            isValid={isValid}
            loading={loading}
            tLabels={tLabels}
          ></SubmitForm>
        </Form>
      )}
    </Formik>
  );
};

export default ConsentPage;
