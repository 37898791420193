import { apiAddress, countryCodes } from "../constants";
import axios from "axios";

export default async function storeDownloadInfo(newState) {
  try {
    let temp = {
      firstName: newState.firstName,
      lastName: newState.lastName,
      email: newState.email,
      companyName: newState.companyName,
      //  blueBeamDisplayName: "",
      country: newState.country,
      state: newState.state === "N/A" ? "" : newState.state,
      language: newState.language,
      // phone: (
      //   countryCodes.find((x) => x.code === newState.countryCode).number +
      //   " " +
      //   newState.phone
      // ).trim(),
      // phoneExt: newState.phoneExt,
      numberOfEmployees: newState.numberOfEmployees,
      industry: newState.industry,
      jobRole: newState.jobRole,
      purchasePlanDesc: newState.purchasePlanDesc,
      source: "Website",
      optIn: newState.optIn,
      sessionId: newState.sessionId,
      awsregion: newState.awsRegion,
    };
    let json = JSON.stringify(temp);

    const request = await axios.post(`${apiAddress}/SaveDownloadInfo`, json);
    return { status: request.status, data: request.data };
  } catch (e) {
    console.log("error in storing download");
    console.log(e);
    console.log(e.status);
    return { status: "error", data: {} };
  }
}
