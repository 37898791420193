import { apiAddress } from "../constants";
import axios from "axios";

export default async function fetchLanguages() {
  try {
    const request = await axios.get(
      `${apiAddress}/GetLanguageAndCountryOptions`
    );
    return {
      countries: request.data.countries,
      languages: request.data.languages,
      industries: request.data.industries,
      states: request.data.states,
      jobRoles: request.data.roles,
      timings: request.data.timings,
      employees: request.data.employees,
    };
  } catch (e) {
    console.log("error fetching languages");
    console.log(e);
  }
}
