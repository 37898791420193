import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
  Paper,
  Box,
  CircularProgress,
  Backdrop,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, getIn } from "formik";
import styling from "../IndexPage/index.css";

import validateContact from "../../API/methods/validateContact";

import { makeStyles } from "@material-ui/styles";

import AppContext from "../../Context/appContext";

import compImg from "../../Assets/comp-right-sm.png";

import penImg from "../../Assets/objects_office-writingUtensils.svg";
import hardHatImg from "../../Assets/objects_construction-hardHat.svg";
import cogImg from "../../Assets/concepts_settings-gear.svg";

import toolsImg from "../../Assets/concepts_settings-tools-01.svg";
import calcImg from "../../Assets/objects_office-calculator.svg";
import walkieImg from "../../Assets/objects_office-walkieTalkie.svg";

import useTranslations from "../../Components/useTranslations";

import { login } from "../../API/methods/login";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AlertTitle } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  grid: {
    width: "100%",
    margin: "0px",
    padding: 10,
  },
  paper: {
    boxShadow: "none !important",
    fontWeight: "none !important",
    color: "#19191E",
  },
}));

const Index = () => {
  const appContext = useContext(AppContext);
  const { updateAccountInfo, appState } = appContext;

  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [reg, setRegion] = useState("");

  const regionsSelectionString =
    process.env.REACT_APP_REGION_SELECTOR ||
    '[{"id": "us-east-1", "label": "USA-l", "url":""},{"id":"eu-central-1", "label":"GER-l", "url":""},{"id":"eu-north-1", "label":"SWE-l", "url":""},{"id":"ap-southeast-2", "label":"AU-l", "url":""}]';
  let regionsSelection = JSON.parse(regionsSelectionString);

  const awsRegion = process.env.REACT_APP_AWS_REGION || "us-east-1";

  const verifyRegion = () => {
    for (let i = 0; i < regionsSelection.length; i++) {
      if (regionsSelection[i].id === awsRegion) {
        setRegion(regionsSelection[i].id);
        return;
      }
    }
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
    for (let i = 0; i < regionsSelection.length; i++) {
      if (e.target.value === regionsSelection[i].id) {
        if (regionsSelection[i].url !== "") {
          window.location.href = regionsSelection[i].url;
        }
        return;
      }
    }
  };

  const tLabels = useTranslations(appState);

  useEffect(() => {
    if (Object.keys(tLabels).length > 0) {
      setIsLoading(false);
    }
  }, [tLabels]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (searchParams.get("message")) {
      setError(true);
      setErrorMessage(searchParams.get("message"));
    }
  }, [searchParams]);

  useEffect(() => {
    verifyRegion();
  }, []);

  const labels = {
    left: [
      { label: tLabels?.trial_whos_revu_desc_1_indexPage, img: penImg },
      { label: tLabels?.trial_whos_revu_desc_2_indexPage, img: cogImg },
      {
        label: tLabels?.trial_whos_revu_desc_3_indexPage,
        img: hardHatImg,
      },
    ],
    right: [
      { label: tLabels?.trial_whos_revu_desc_4_indexPage, img: toolsImg },
      { label: tLabels?.trial_whos_revu_desc_5_indexPage, img: calcImg },
      {
        label: tLabels?.trial_whos_revu_desc_6_indexPage,
        img: walkieImg,
      },
    ],
  };

  const RevuList = () => {
    const revuItems = [
      {
        labelBolded: tLabels?.trial_index_bullet1,
        regLabel: tLabels?.trial_index_desc1,
      },
      {
        labelBolded: tLabels?.trial_index_bullet2,
        regLabel: tLabels?.trial_index_desc2,
      },
      {
        labelBolded: tLabels?.trial_index_bullet3,
        regLabel: tLabels?.trial_index_desc3,
      },
    ];
    return (
      <div
        style={{
          padding: 20,
          backgroundColor: "#f7f9fc",
          borderRadius: 4,
          boxShadow: "0 1px 1px rgba(0,0,0,0.01), 0 1px 1px rgba(0,0,0,0.07)",
        }}
      >
        <Typography variant="h4" fontSize={20}>
          {tLabels?.trial_revu_heading_indexPage}
        </Typography>

        <ul>
          {revuItems.map((item, index) => {
            return (
              <li key={index} style={{ fontSize: 20 }}>
                <Typography
                  variant="body1"
                  style={{ marginTop: 5 }}
                  fontSize={16}
                >
                  <b>{item.labelBolded}</b>
                  {item.regLabel}
                </Typography>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error}
        onClose={handleClose}
        autoHideDuration={3000}
        message={errorMessage}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          style={{ width: "300px" }}
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>

      {loading ? (
        <Backdrop sx={{ color: "#fff" }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Grid
          container
          spacing={1}
          justifyContent="space-around"
          style={{ padding: 20 }}
        >
          <Grid item xs={12} md={7} lg={7}>
            <Grid container>
              <Grid item xs={12} md={8} lg={8} style={{ marginRight: 10 }}>
                <Typography variant="h3" fontSize={40} fontWeight={"bold"}>
                  {tLabels?.trial_heading_indexPage}
                </Typography>
                <Box style={{ marginTop: 39, marginBottom: 60 }}>
                  <Typography variant="body1" fontSize={16}>
                    {tLabels?.trial_heading_desc_indexPage}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Paper
                  style={{ textAlign: "center" }}
                  className={classes.paper}
                >
                  <img src={compImg} alt="" />
                </Paper>
              </Grid>
            </Grid>

            <Grid item xs={12} md={11} lg={11}>
              <Paper className={classes.paper}>
                <RevuList />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  mt={10}
                  style={{ marginTop: 50 }}
                >
                  <Typography variant="h4" fontSize={20} fontWeight={"bold"}>
                    {tLabels?.trial_index_header2}
                  </Typography>
                </Grid>

                <div className="iconContainer">
                  <div>
                    {labels.left.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                          key={index}
                        >
                          <img
                            src={item.img}
                            alt={item.label}
                            style={{ width: 53, marginRight: 20 }}
                          />
                          <Typography variant="body1" fontSize={16}>
                            {item.label}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>

                  <div>
                    {labels.right.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                          key={index}
                        >
                          <img
                            src={item.img}
                            alt={item.label}
                            style={{ width: 53, marginRight: 20 }}
                          />
                          <Typography variant="body1" fontSize={16}>
                            {item.label}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
          {/* divider */}
          <Divider orientation="vertical" flexItem padding="1" />
          {/* divider */}
          <Grid item xs={12} md={3} lg={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <Typography variant="h4" fontSize={28} fontWeight={"bold"}>
                  {tLabels?.trial_revu_download_heading_indexPage}
                </Typography>
                <div style={{ textAlign: "center", marginTop: 30 }}></div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <label
                    style={{ display: "block", marginTop: 10, fontSize: 14 }}
                  >
                    {tLabels?.trial_revu_server_region_index}
                  </label>
                  <Tooltip
                    title={
                      tLabels?.trial_revu_server_region_info_index
                        ? tLabels?.trial_revu_server_region_info_index
                        : ""
                    }
                    fontSize={16}
                  >
                    <i
                      style={{
                        marginTop: "10px",
                        marginRight: "2px",
                        marginLeft: "3px",
                      }}
                      className="icon icon--infoCircle"
                    ></i>
                  </Tooltip>
                </div>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="country"
                    id="country-select"
                    name="country"
                    required
                    displayEmpty
                    value={reg}
                    onChange={handleRegionChange}
                  >
                    {regionsSelection.map((ack) => (
                      <MenuItem key={ack.id} value={ack.id}>
                        {ack.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <PersonForm
                  appState={appState.accountForm}
                  updateAccountInfo={updateAccountInfo}
                  tLabels={tLabels}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const PersonForm = ({ appState, updateAccountInfo, tLabels }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false); // set true if email has been used
  const [errorMsg, setErrorMsg] = useState(
    tLabels?.trial_revu_download_previously
  );

  const [hide, setHide] = useState(true);
  const [show, setShow] = useState(false);

  const initialValues = {
    email: appState.email || "",
  };

  const verifyEmail = async (email) => {
    setLoading(true);
    let response = await validateContact(email);
    setLoading(false);

    if (response.status === 409) {
      setErrorMsg(tLabels?.trial_revu_existing_user_text);
      setError(true);
      return;
    } else if (response.status !== 200) {
      setError(true);
      return;
    }
    if (response === undefined) {
      setError(true);
      return;
    }
    setError(false);
    setErrorMsg("");
    setHide(false);
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      validationSchema={Yup.object().shape({
        firstName: show
          ? Yup.string().required(tLabels?.trial_revu_firstName_error_indexPage)
          : undefined,
        lastName: show
          ? Yup.string().required(tLabels?.trial_revu_lastName_error_indexPage)
          : undefined,
        email: Yup.string()
          .email(tLabels?.trial_revu_validEmail_error_indexPage)
          .required(tLabels?.trial_revu_emailReq_error_indexPage),
      })}
      onSubmit={async (values) => {
        setLoading(true);

        let response = await validateContact(values.email);
        setLoading(false);

        if (response.status === 409) {
          setErrorMsg(tLabels?.trial_revu_existing_user_text);
          setError(true);
        } else if (response.status !== 200) {
          setError(true);
          return;
        }
        if (response === undefined) {
          setError(true);
          return;
        }

        let isAllowed = JSON.parse(response.data.downloadAllowed);

        if (isAllowed) {
          updateAccountInfo({
            ...appState.accountForm,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            isFormProcessStarted: true,
            sessionId: response.data.sessionId,
          });
          navigate("/AccountCreation");
        } else {
          setError(true);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
        touched,
        values,
      }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <label style={{ display: "block", marginTop: 20, fontSize: 14 }}>
                {tLabels?.trial_revu_download_workEmail_indexPage}
              </label>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              style={{ display: hide ? "none" : undefined }}
            >
              <label style={{ display: "block", marginTop: 10, fontSize: 14 }}>
                {tLabels?.trial_revu_download_firstName_indexPage}
              </label>
              <TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                required
                helperText={touched.firstName && errors.firstName}
                inputProps={{
                  style: {
                    // height: 50,
                  },
                }}
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              style={{ display: hide ? "none" : undefined }}
            >
              <label style={{ display: "block", marginTop: 10, fontSize: 14 }}>
                {tLabels?.trial_revu_download_lastName_indexPage}
              </label>
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                required
                helperText={touched.lastName && errors.lastName}
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Box pt={2}>
            {isError ? (
              <Typography variant="body2">
                <span
                  className="cSpanErr"
                  style={{
                    height: 20,
                    fontSize: 14,
                  }}
                >
                  {errorMsg}
                </span>
                <span
                  className="cSpanErrLink"
                  style={{
                    color: "#0099DE",
                    height: 20,
                    fontWeight: "bold",
                    fontSize: 15,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    login(appState, "IndexPage", "", sessionStorage.resellerId)
                  }
                >
                  {tLabels?.trial_revu_existing_user_link}
                </span>
              </Typography>
            ) : (
              ""
            )}
          </Box>
          <Button
            variant="contained"
            type="submit"
            disabled={Boolean(!isValid)}
            fullWidth
            style={{
              height: 55,
              background: "#0c8cdc",
              marginTop: 25,
              textTransform: "none",
              boxShadow: "none",
              display: hide ? "none" : undefined,
            }}
          >
            {loading && (
              <CircularProgress size={14} style={{ color: "white" }} />
            )}
            <span style={isValid ? { color: "white" } : {}}>
              {!loading && tLabels?.trial_revu_download_conBtn_indexPage}
            </span>
          </Button>
          <Button
            variant="contained"
            type="button"
            disabled={Boolean(!isValid)}
            fullWidth
            style={{
              height: 55,
              background: "#0c8cdc",
              marginTop: 25,
              textTransform: "none",
              boxShadow: "none",
              display: hide ? undefined : "none",
            }}
            onClick={() => verifyEmail(values.email)}
          >
            {loading && (
              <CircularProgress size={14} style={{ color: "white" }} />
            )}
            <span style={isValid ? { color: "white" } : {}}>
              {!loading && tLabels?.trial_revu_download_conBtn_indexPage}
            </span>
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Index;
