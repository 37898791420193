import { loginUrl } from "../constants";
import { clientId } from "../constants";
import { callbackUrl } from "../constants";
import { apiAddress } from "../constants";
import getPkce from "oauth-pkce";
import axios from "axios";

const login = async (appState, fromPath, id) => {
  const { verifier, challenge } = await generatePkce();
  setSessionStorage("pkce_verifier", verifier);
  setSessionStorage("from_path", fromPath);
  setSessionStorage("id_token", id);
  const loginUri = `${loginUrl}/connect/authorize?client_id=${clientId}&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(
    callbackUrl + "/authorize_callback"
  )}&code_challenge_method=S256&code_challenge=${challenge}&state=${generateState(
    20
  )}`;
  window.location.assign(loginUri);
};

const token = async (code) => {
  const verifier = getSessionStorage("pkce_verifier");
  if (verifier === null) {
    throw new Error("PKCE Verifier token not found");
  }
  const request = await axios.post(
    `${apiAddress}/Token`,
    {
      code: code,
      code_verifier: verifier,
      redirect_uri: `${callbackUrl}/authorize_callback`,
    },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return request.data;
};

const setSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

const getSessionStorage = (key) => {
  return window.sessionStorage.getItem(key);
};

const generatePkce = () => {
  return new Promise((resolve, reject) => {
    getPkce(64, (error, { verifier, challenge }) => {
      if (error) {
        reject(error);
      }
      resolve({ verifier, challenge });
    });
  });
};

const generateState = (length) => {
  const validCharacters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let state = "";
  for (let i = 0; i < length; i++)
    state += validCharacters.charAt(
      Math.floor(Math.random() * validCharacters.length)
    );

  return state;
};

export { login, token, setSessionStorage, getSessionStorage };
