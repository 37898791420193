import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import React, { useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getSessionStorage, token } from "../API/methods/login";
import AppContext from "../Context/appContext";

const LoginCallback = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { updateAccountInfo, appState } = appContext;

  useEffect(() => {
    setLoading(true);
    if (!validateUrlParameters(searchParams)) {
      navigate({
        pathname: "/",
        search: createSearchParams({
          message: "Missing parameters from oauth",
        }).toString(),
      });
      return;
    }
    const getToken = async () => {
      try {
        const response = await token(searchParams.get("code"));
        await handleTokenResponse(response);
      } catch (e) {
        setLoading(false);
        navigate({
          pathname: "/",
          search: createSearchParams({
            message: e.message,
          }).toString(),
        });
      }
    };
    getToken();

  }, []);
  const handleTokenResponse = async (tokenResponse) => {
    const fromPath = getSessionStorage("from_path");
    if (!fromPath && fromPath !== "") {
      throw new Error("Internal error");
    }
    if (fromPath === "IndexPage") {
      await handleIndexPageResponse(tokenResponse);
    } else if (fromPath === "RegisterPage") {
      await handleRegisterPageResponse(tokenResponse);
    }
  };

  const handleRegisterPageResponse = async (tokenResponse) => {
    const id = getSessionStorage("id_token");
    if (!id && id !== "") {
      throw new Error("internal error");
    }
    setLoading(false);
    navigate(`/CompleteLogin/${id}`);
  };

  const handleIndexPageResponse = async (tokenResponse) => {
    if (tokenResponse === null || tokenResponse.sessionId === null) {
      throw new Error("Session id not found!");
    }
    if (!JSON.parse(tokenResponse.downloadAllowed)) {
      throw new Error("Trial has been downloaded from this email before.");
    }
    updateAccountInfo({
      ...appState.accountForm,
      email: tokenResponse.Email,
      optIn: false,
      purchasePlanDesc: "",
      sessionId: tokenResponse.sessionId,
      salesforceId: tokenResponse.SalesforceId,
      isFormComplete: true,
      isLoginAndConsent: true,
    });
    navigate("/ConsentPage");
  };

  const validateUrlParameters = (parameters) => {
    if (
      parameters.get("code") === null ||
      parameters.get("code") === "" ||
      parameters.get("state") === null ||
      parameters.get("state") === ""
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff" }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LoginCallback;
