import { useEffect, useState } from "react";


// because of STRelief ticket LIC-4483 and incomplete translations to allow and override (to English for this ticket)
// TODO (Tony) remove this hack (someday we will have translations for all the copy text)
const useTranslations = (appState, keyOverride) => {
  const [tLabels, setLabels] = useState({});
  useEffect(() => {
    let labels = JSON.parse(sessionStorage.getItem("labels"));

    let key = appState.language.label
      ? appState.language.value
      : JSON.parse(appState.language).value;

    // this hack
    key = keyOverride || key;

    if (labels !== null) {
      setLabels(labels[key]);
    }
  }, [appState.language, appState.isTranslationsFetched]);

  return tLabels;
};

export default useTranslations;
