import React, { useState, useEffect, useContext, useRef } from "react";
import { Wizard, useWizard } from "react-use-wizard";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Select,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import AppContext from "../../Context/appContext";
import storeDownloadInfo from "../../API/methods/storeDownloadInfo";
import preRegisterUser from "../../API/methods/preRegisterUser";

import logo from "../../Assets/Bluebeam-Logo.png";
import styling from "../WizardPage/index.css";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import useTranslations from "../../Components/useTranslations";
import usePicklistValues from "../../Components/usePicklistValues";
import StateSelect from "../../Components/stateSelect";
import { countryCodes } from "../../API/constants";
import flagCSS from "../../Assets/flags.css";

const AWS_REGION = process.env.REACT_APP_AWS_REGION;

function WizardPage() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { updateAccountInfo, updateLanguageCountry, appState } = appContext;
  const [activeStep, setActiveStep] = React.useState(0);

  const handleIncrement = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleDecrement = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const tLabels = useTranslations(appState);
  usePicklistValues(appState, updateLanguageCountry);
  if (!appState.accountForm.isFormProcessStarted) {
    navigate("/");
  }

  const {
    countries,
    states,
    languages,
    industries,
    employees,
    jobRoles,
    timings,
  } = appState;

  return (
    <React.Fragment>
      <Box paddingLeft={5} paddingRight={5}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            border: "1px solid #DADADA",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ padding: 20, borderBottom: "1px solid #DADADA" }}
          >
            <Typography
              variant="h6"
              style={{ textTransform: "none", paddingTop: 10 }}
            >
              {tLabels?.trial_createYourBBAcc_wizardPage}
            </Typography>
            <Typography
              variant="h6"
              style={{
                textTransform: "uppercase",
                marginTop: 22,
                color: "#0099DE",
                fontSize: 16,
                borderBottom: "1px soldi red",
              }}
            >
              {tLabels?.trial_createAcc_wizardPage}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            style={{
              padding: 20,
              borderRight: "1px solid #DADADA",
              borderTop: "4px solid #0099de",
            }}
          >
            <VerticalLinearStepper activeStep={activeStep} tLabels={tLabels} />
          </Grid>
          <Grid
            item
            xs={10}
            md={8}
            style={{
              padding: 20,
              background: "#F1F2F2",
            }}
          >
            <Wizard>
              <Step1
                appState={appState}
                handleIncrement={handleIncrement}
                updateAccountInfo={updateAccountInfo}
                tLabels={tLabels}
              />
              <Step2
                appState={appState}
                countries={countries}
                states={states}
                languages={languages}
                industries={industries}
                jobRoles={jobRoles}
                employees={employees}
                handleDecrement={handleDecrement}
                handleIncrement={handleIncrement}
                updateAccountInfo={updateAccountInfo}
                tLabels={tLabels}
              />
              <Step3
                appState={appState}
                handleDecrement={handleDecrement}
                updateAccountInfo={updateAccountInfo}
                timings={timings}
                tLabels={tLabels}
              />
            </Wizard>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

const VerticalLinearStepper = ({ activeStep, tLabels }) => {
  const steps = [
    {
      label: tLabels?.trial_personalInfo_wizardPage,
      description: ``,
    },
    {
      label: tLabels?.trial_companyDetails_wizardPage,
      description: "",
    },
    {
      label: tLabels?.trial_finishAccCreation_wizardPage,
      description: ``,
    },
  ];

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel style={{ fontSize: 16 }}>{step.label}</StepLabel>
            {/* <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent> */}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const Step1 = ({ appState, handleIncrement, updateAccountInfo, tLabels }) => {
  const { nextStep } = useWizard();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false); // set true if response !== success
  const [errorMsg, setErrorMsg] = useState(
    tLabels?.trial_server_error_wizardPage
  );
  const resettingRef = useRef(false);

  const initialValues = {
    name: appState.accountForm.name || "",
    password: appState.accountForm.password || "",
    confirmPassword: appState.accountForm.password || "",
  };

  const handleRestart = () => {
    navigate("/");
  };

  useEffect(() => {
    if (resettingRef.current) {
      resettingRef.current = false;
      next();
    }
  }, [appState]);

  const next = async () => {
    handleIncrement();
    nextStep();
    // setLoading(true);
    // //todo, to check that if user comes back, we don't pre register again? how to handle update
    // const response = await preRegisterUser(appState.accountForm);
    // if (response && response.status === 200) {
    //   setLoading(false);
    //   handleIncrement();
    //   nextStep();
    // } else if (response && response.status === 440) {
    //   setErrorMsg(tLabels?.trial_sessionInvalid_error_wizardPage);
    //   setLoading(false);
    //   setError(true);
    // } else {
    //   setLoading(false);
    //   setError(true);
    //   setErrorMsg(
    //     response.data ? response.data : tLabels?.trial_server_error_wizardPage
    //   );
    // }
  };
  return (
    <>
      <Typography variant="h6">
        {tLabels?.trial_personalInfo_wizardPage}
      </Typography>
      <Typography variant="body1">
        {tLabels?.trial_provideMoreInfo_wizardPage}
      </Typography>
      <Grid container style={{ marginTop: 38, marginBottom: 30 }}>
        <Grid item xs={6} md={6} lg={6}>
          <label style={{ display: "block", fontWeight: "normal" }}>
            {tLabels?.trial_firstName_wizardPage}
          </label>
          <label style={{ display: "block" }}>
            {appState.accountForm.firstName}
          </label>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <label style={{ display: "block" }}>
            {tLabels?.trial_lastName_wizardPage}
          </label>
          <label style={{ display: "block" }}>
            {appState.accountForm.lastName}
          </label>
        </Grid>
        <Grid item xs={12} md={12} style={{ marginTop: 26 }}>
          <label style={{ display: "block" }}>
            {tLabels?.trial_workEmail_wizardPage}
          </label>
          <label style={{ display: "block" }}>
            {appState.accountForm.email}
          </label>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          ...initialValues,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            tLabels?.trial_displayName_error_wizardPage
          ),
          password: Yup.string()
            .required(tLabels?.trial_noPassword_error_wizardPage)
            .min(8, tLabels?.trial_passwordShort_error_wizardPage)
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              tLabels?.trial_passwordStrength_error_wizardPage
            ),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            tLabels?.trial_passwordMatch_error_wizardPage
          ),
        })}
        onSubmit={async (values) => {
          await updateAccountInfo({
            ...appState.accountForm,
            name: values.name,
            password: values.password,
            confirmPassword: values.password,
          });
          // next();
          resettingRef.current = true;
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          touched,
          values,
        }) => (
          <Form autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block", fontWeight: "bold" }}>
                  {tLabels?.trial_displayName_wizardPage}
                </label>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  required
                  helperText={touched.name && errors.name}
                  name="name"
                  className="InputElem"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  autoComplete="off"
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <label
                  style={{
                    display: "block",
                    marginTop: 26,
                    fontWeight: "bold",
                  }}
                >
                  {tLabels?.trial_password_wizardPage}
                </label>
                <label
                  style={{ display: "block", marginTop: 5, fontSize: 12 }}
                  className="smlabel"
                >
                  {tLabels?.trial_passwordDesc_wizardPage}
                </label>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  required
                  helperText={touched.password && errors.password}
                  name="password"
                  className="InputElem"
                  autoComplete="new-password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <label
                  style={{
                    display: "block",
                    marginTop: 26,
                    fontWeight: "bold",
                  }}
                >
                  {tLabels?.trial_confirmPassword_wizardPage}
                </label>
                <TextField
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  name="confirmPassword"
                  autoComplete="off"
                  className="InputElem"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                  required
                />
              </Grid>
            </Grid>
            <Box pt={2}>
              {isError ? (
                <Typography
                  component="h6"
                  color="error"
                  variant="p"
                  fontSize={14}
                >
                  {errorMsg}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleRestart}
                style={{
                  height: 55,
                  padding: "17px 48px",
                  width: 200,
                  background: "white",
                  textTransform: "none",
                  boxShadow: "none",
                }}
              >
                {tLabels?.trial_startOverBtn_wizardPage}
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={Boolean(!isValid)}
                style={{
                  height: 55,
                  padding: "17px 48px",
                  width: 200,
                  textTransform: "none",
                  boxShadow: "none",
                }}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: "white" }} />
                )}
                {!loading && tLabels?.trial_nextBtn_wizardPage}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const Step2 = ({
  appState,
  countries,
  states,
  languages,
  industries,
  employees,
  jobRoles,
  handleDecrement,
  handleIncrement,
  updateAccountInfo,
  tLabels,
}) => {
  const { nextStep } = useWizard();
  const next = () => {
    handleIncrement();
    nextStep();
  };
  const initialValues = {
    companyName: appState.accountForm.companyName || "",
    country: appState.accountForm.country || "",
    language: appState.accountForm.language || "",
    state: appState.accountForm.state,
    numberOfEmployees: appState.accountForm.numberOfEmployees || "",
    phone: appState.accountForm.phone || "",
    phoneExt: appState.accountForm.phoneExt || "",
    countryCode: appState.accountForm.countryCode || "",
    industry: appState.accountForm.industry || "",
    jobRole: appState.accountForm.jobRole || "",
  };

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
        }}
        validationSchema={Yup.object().shape({
          companyName: Yup.string().required(
            tLabels?.trial_companyNameReq_wizardPage
          ),
          country: Yup.string()
            .ensure()
            .required(tLabels?.trial_countryReq_wizardPage),
          state: Yup.string().when("country", {
            is: (country) =>
              states.filter((e) => e.country === country).length > 0,
            then: Yup.string().required(tLabels?.trial_stateReq_wizardPage),
            otherwise: Yup.string(),
          }),
          language: Yup.string()
            .ensure()
            .required(tLabels?.trial_languageReq_wizardPage),
          numberOfEmployees: Yup.string(
            tLabels?.trial_numbersOnlyReq_wizardPage
          )
            .ensure()
            .required(tLabels?.trial_numEmployeesReq_wizardPage),
          // phone: Yup.string()
          //   .required(
          //     tLabels?.trial_phoneNumber_wizardPage +
          //       " " +
          //       tLabels?.trial_required?.toLowerCase()
          //   )
          //   .matches(/^[0-9]+$/, tLabels?.trial_phone_type_req_wizardPage)
          //   .min(1, tLabels?.trial_phone_length_req_wizardPage)
          //   .max(40, tLabels?.trial_phone_length_req_wizardPage),
          // phoneExt: Yup.string()
          //   .matches(/^[0-9]+$/, tLabels?.trial_phone_type_req_wizardPage)
          //   .min(1, tLabels?.trial_phone_length_req_wizardPage)
          //   .max(10, tLabels?.trial_phone_length_req_wizardPage),
          // countryCode: Yup.string()
          //   .ensure()
          //   .required(tLabels?.trial_countryReq_wizardPage),
          industry: Yup.string().required(
            tLabels?.trial_industryReq_wizardPage
          ),
          jobRole: Yup.string().required(tLabels?.trial_jobRoleReq_wizardPage),
        })}
        onSubmit={async (values) => {
          updateAccountInfo({
            ...appState.accountForm,
            companyName: values.companyName,
            country: values.country,
            state: values.state,
            language: values.language,
            numberOfEmployees: values.numberOfEmployees,
            phone: values.phone,
            phoneExt: values.phoneExt,
            countryCode: values.countryCode,
            industry: values.industry,
            jobRole: values.jobRole,
            awsRegion: AWS_REGION,
          });
          next();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          touched,
          values,
        }) => (
          <Form autoComplete="off">
            <Typography variant="h6">
              {tLabels?.trial_companyDetails_wizardPage}
            </Typography>
            <Typography variant="body1">
              {tLabels?.trial_companyInfo_wizardPage}
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block", marginTop: 30 }}>
                  {tLabels?.trial_companyName_wizardPage}
                </label>
                <TextField
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  helperText={touched.companyName && errors.companyName}
                  name="companyName"
                  className="InputElem"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.companyName}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_country_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="country"
                    id="country-select"
                    name="country"
                    required
                    displayEmpty
                    value={values.country}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.country && errors.country)}
                    // helperText={touched.country && errors.country}
                  >
                    {countries.map((country) => {
                      return (
                        <MenuItem key={country} value={country}>
                          {country}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <StateSelect
                  statesByCountry={states}
                  labels={tLabels}
                ></StateSelect>
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_language_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="language"
                    id="language-select"
                    name="language"
                    required
                    displayEmpty
                    value={values.language}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.language && errors.language)}
                    // helperText={touched.language && errors.language}
                  >
                    {languages.map((language) => {
                      return (
                        <MenuItem key={language} value={language}>
                          {language}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_numbEmployees_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="numberOfEmployees"
                    id="numberOfEmployees-select"
                    name="numberOfEmployees"
                    required
                    displayEmpty
                    value={values.numberOfEmployees}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(
                      touched.numberOfEmployees && errors.numberOfEmployees
                    )}
                  >
                    {employees.map((employee) => {
                      return (
                        <MenuItem key={employee} value={employee}>
                          {employee}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_industry_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="industry"
                    id="industry-select"
                    name="industry"
                    required
                    displayEmpty
                    value={values.industry}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    error={Boolean(touched.industry && errors.industry)}
                    // helperText={touched.industry && errors.industry}
                  >
                    {industries.map((industry) => {
                      return (
                        <MenuItem key={industry} value={industry}>
                          {industry}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_jobRole_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="jobRole"
                    id="jobRole-select"
                    name="jobRole"
                    required
                    displayEmpty
                    value={values.jobRole}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    error={Boolean(touched.jobRole && errors.jobRole)}
                    // helperText={touched.industry && errors.industry}
                  >
                    {jobRoles.map((role) => {
                      return (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item md={2} xs={2} style={{ minWidth: "140px" }}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_country_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    error={Boolean(touched.countryCode && errors.countryCode)}
                    fullWidth
                    helperText={touched.countryCode && errors.countryCode}
                    name="countryCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    renderValue={(selectedValue) => {
                      return (
                        <div>
                          <i
                            className={"flag " + selectedValue.toLowerCase()}
                          />
                          &nbsp;
                          {
                            countryCodes.find((x) => x.code === selectedValue)
                              .number
                          }
                        </div>
                      );
                    }}
                    type="text"
                    value={values.countryCode}
                    variant="outlined"
                    required
                  >
                    {countryCodes.map((country) => {
                      return (
                        <MenuItem key={country.code} value={country.code}>
                          <i className={"flag " + country.code.toLowerCase()} />
                          &nbsp;
                          {country.name + " " + country.number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={true} xs={true}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_phoneNumber_wizardPage?.slice(-1) === "*"
                    ? tLabels?.trial_phoneNumber_wizardPage
                    : tLabels?.trial_phoneNumber_wizardPage + "*"}
                </label>
                <FormControl fullWidth className="InputElem">
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    name="phone"
                    className="InputElem"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={2} xs={2}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_extension_wizardPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <TextField
                    error={Boolean(touched.phoneExt && errors.phoneExt)}
                    fullWidth
                    helperText={touched.phoneExt && errors.phoneExt}
                    name="phoneExt"
                    className="InputElem"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.phoneExt}
                    variant="outlined"
                  />
                </FormControl>
              </Grid> */}
            </Grid>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" fontSize={16}>
                {tLabels?.trial_wizard_required}
              </Typography>
              <Button
                variant="contained"
                type="submit"
                disabled={Boolean(!isValid)}
                style={{
                  height: 55,
                  padding: "17px 48px",
                  width: 200,
                  marginLeft: "auto",
                  textTransform: "none",
                  boxShadow: "none",
                }}
              >
                {tLabels?.trial_nextBtn_wizardPage}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const Step3 = ({
  appState,
  updateAccountInfo,
  handleDecrement,
  tLabels,
  timings,
}) => {
  const { previousStep, nextStep } = useWizard();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false); // set true if response !== success
  const [errorMsg, setErrorMsg] = useState("Error - Something went wrong");

  const handleApprove = () => {
    //handleClose(); //close tos modal
    handleComplete();
  };

  const initialValues = {
    purchasePlanDesc: appState.accountForm.purchasePlanDesc || "",
    checked: false,
    consentChecked: false,
  };

  const handlePrevious = () => {
    handleDecrement();
    previousStep();
  };

  const handleComplete = async () => {
    setLoading(true);

    let registerResponse = await preRegisterUser(appState.accountForm);
    if (registerResponse && registerResponse.status === 440) {
      setErrorMsg(tLabels?.trial_sessionInvalid_error_wizardPage);
      setError(true);
      setLoading(false);
      return;
    } else if (registerResponse && registerResponse.status !== 200) {
      setErrorMsg(
        registerResponse.data
          ? registerResponse.data
          : tLabels?.trial_server_error_wizardPage
      );
      setError(true);
      setLoading(false);
      return;
    }

    let storeResponse = await storeDownloadInfo(appState.accountForm);
    if (storeResponse.data.status === "SUCCESS") {
      setLoading(false);
      updateAccountInfo({
        ...appState.accountForm,
        isFormProcessStarted: false, //we reset after form submission so user cant come back here
        optIn: false,
        firstName: "",
        lastName: "",
        email: "",
        name: "",
        password: "",
        companyName: "",
        country: "",
        language: "",
        phone: "",
        phoneExt: "",
        countryCode: "",
        numberOfEmployees: "",
        industry: "",
        jobRole: "",
        purchasePlanDesc: "",
        source: "Website",
        state: "",
        sessionId: "",
        isFormComplete: true,
      });
      navigate("/Download");
    } else {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
        }}
        validationSchema={Yup.object().shape({
          purchasePlanDesc: Yup.string()
            .required(tLabels?.trial_purchasePlanReq_wizardPage)
            .ensure(),
          consentChecked: Yup.bool()
            .oneOf([true], "Must be checked")
            .required(),
        })}
        onSubmit={(values) => {
          appState.accountForm.optIn = values.checked;
          appState.accountForm.purchasePlanDesc = values.purchasePlanDesc;
          handleApprove();
        }}
        validateOnMount={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          touched,
          values,
        }) => (
          <Form autoComplete="off">
            <Typography variant="h6">
              {tLabels?.trial_finishAccCreation_wizardPage}
            </Typography>
            <Typography variant="body1">
              {tLabels?.trial_questions_wizardPage}
            </Typography>

            <Grid container spacing={2}>
              <Grid item md={12} xs={12} style={{ marginTop: 10 }}>
                <label style={{ display: "block" }}>
                  {tLabels?.trial_revu_download_purchasePlan_indexPage}
                </label>
                <FormControl fullWidth className="InputElem">
                  <Select
                    labelId="purchasePlanDesc"
                    id="purchasePlanDesc-select"
                    name="purchasePlanDesc"
                    required
                    displayEmpty
                    value={values.purchasePlanDesc}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    renderValue={(values) =>
                      values ? (
                        values
                      ) : (
                        <div style={{ color: "#aaa" }}>
                          {tLabels?.trial_select_placeholder}
                        </div>
                      )
                    }
                    error={Boolean(
                      touched.purchasePlanDesc && errors.purchasePlanDesc
                    )}
                    // helperText={touched.industry && errors.industry}
                  >
                    {timings.map((plan) => {
                      return (
                        <MenuItem key={plan} value={plan}>
                          {plan}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormGroup style={{ marginBottom: 30, marginTop: 33 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checked"
                      name="checked"
                      checked={values.checked}
                      error={Boolean(touched.checked && errors.checked)}
                      onChange={handleChange}
                    />
                  }
                  label={tLabels?.trial_revu_download_allowEmail_indexPage}
                />
              </FormGroup>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormGroup style={{ marginBottom: 30, marginTop: 33 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="consentChecked"
                      name="consentChecked"
                      checked={values.consentChecked}
                      error={Boolean(
                        touched.consentChecked && errors.consentChecked
                      )}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Typography variant="body1">
                      <b>
                        {tLabels?.trial_revu_download_privacy_policy_section1}
                      </b>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={tLabels?.trial_revu_download_privacy_link1}
                      >
                        {tLabels?.trial_revu_download_privacy_link1_text}
                      </a>
                      <b>
                        {tLabels?.trial_revu_download_privacy_policy_section4}
                      </b>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={tLabels?.trial_revu_download_privacy_link2}
                      >
                        {tLabels?.trial_revu_download_privacy_link2_text}
                      </a>
                      <b>
                        {tLabels?.trial_revu_download_privacy_policy_section2}
                      </b>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={tLabels?.trial_revu_download_privacy_link3}
                      >
                        {tLabels?.trial_revu_download_pricacy_link3_text}
                      </a>

                      <b>
                        {tLabels?.trial_revu_download_privacy_policy_section3}
                      </b>
                      <br></br>
                      <Tooltip
                        title={
                          <span style={{ fontSize: 15 }}>
                            {tLabels?.trial_revu_download_whatispartner}
                          </span>
                        }
                      >
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={tLabels?.trial_revu_download_whatispartnerlink}
                        >
                          {tLabels?.trial_revu_download_whatispartnertext}
                        </a>
                      </Tooltip>

                      <b>
                        {tLabels?.trial_revu_download_privacy_policy_section5}
                      </b>
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
            <Box pt={2}>
              {isError ? (
                <Typography component="h6" color="error" variant="p">
                  {errorMsg}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => handlePrevious()}
                style={{
                  height: 55,
                  padding: "17px 48px",
                  width: 200,
                  background: "white",
                  textTransform: "none",
                  boxShadow: "none",
                }}
              >
                {tLabels?.trial_previousBtn_wizardPage}
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={Boolean(!isValid)}
                style={{
                  height: 55,
                  padding: "17px 48px",
                  width: 200,
                  textTransform: "none",
                  boxShadow: "none",
                }}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: "white" }} />
                )}
                {!loading && tLabels?.trial_completeBtn_wizardPage}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WizardPage;
