import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Box } from "@material-ui/core";
import logo from "../../Assets/Bluebeam-Logo.png";
import mountain from "../../Assets/bluebeam_warning.svg";
import fetchTranslations from "../../API/methods/fetchTranslations";
import AppContext from "../../Context/appContext";
import useTranslations from "../../Components/useTranslations";

const InvalidPage = () => {
  const appContext = useContext(AppContext);
  const { appState } = appContext;

  const [loading, setIsLoading] = useState(false);

  const tLabels = useTranslations(appState);
  return (
    <React.Fragment>
      {loading ? (
        ""
      ) : (
        <Box m={10}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
              border: "1px solid #DADADA",
            }}
          >
            {" "}
            <Grid item xs={12} sm={12} md={12} style={{ padding: 20 }}>
              <Link to="/">
                <img
                  src={logo}
                  alt="bluebeam logo"
                  style={{ height: 45 }}
                ></img>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                padding: 20,
              }}
            >
              <Typography
                variant="h6"
                align="center"
                style={{
                  marginTop: 42,
                  color: "",
                  fontSize: 28,
                  fontWeight: 900,
                }}
              >
                {tLabels?.trial_heading_invalidInvitePage}
              </Typography>
              <span
                style={{
                  textAlign: "center",
                  color: "#000000",
                  margin: "0 auto",
                  display: "block",
                  marginTop: 57,
                }}
              >
                <img src={mountain} alt="mountain"></img>
              </span>
              <Typography
                variant="h6"
                align="center"
                style={{
                  marginTop: 42,
                  color: "",
                  fontSize: 16,
                  fontWeight: "normal",
                  paddingBottom: 60,
                }}
              >
                {tLabels?.trial_heading_desc_invalidInvitePage}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};

export default InvalidPage;
